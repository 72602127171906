<template>
    <div :class="['theme theme-nature', themeMode]">
        <div class="theme-nature-objects">  
          <div ref="nightGradient" class="night-gradient"></div>
            <CloudsDialogBox 
                :content="this.themeContent"
                :clipped="this.cloudsClipped"
                :style="{ 'padding-bottom': bottomOffset + 'vh' }"
            />
            <StowyLogo 
                :logoState="this.logoState"
            />
            <div class="night-stars">
              <img :src="starsImg" class="stars" />
            </div>
            <div class="night-moon">
              <img :src="moonImg" class="moon" />
            </div>
            <img :src="natureLandscapeImg" class="theme-object nature-landscape" />
        </div>
    </div>

</template>  

<script>
import CloudsDialogBox from "@app-components/CloudsDialogBox.vue";
import StowyLogo from '@app-components/StowyLogo.vue'

export default {
    name: "ThemeNature",

    components: {
        CloudsDialogBox,
        StowyLogo,
    },
    props: {
      themeMode: {
        type: String,
        default: "day",
      },
      themeContent: {
        type: Array,
        default: () => [[""]],
      },
      logoState: {
        type: String,
        default: "clipped-top", // animated / clipped-top / hidden
      },
      bottomOffset: {
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        cloudsClipped: "",
        natureLandscapeImg: require('@app-assets/nature-bg.png'),
        moonImg: require('@app-assets/Head-Moon@2x.png'),
        starsImg: require('@app-assets/Head-Stars@2x.png'),
      }
    },
    watch: {
      themeMode(newMode) {
        newMode === "day" ? this.setModeToDay() : this.setModeToNight();
      },
    },
    methods: {
        setModeToDay(){
            this.cloudsClipped="top";
        },
        setModeToNight(){
            this.cloudsClipped="bottom";
        }
    },
    mounted() {
      this.themeMode=== "day" ? this.cloudsClipped = "top" : this.cloudsClipped = "bottom";
    }
    
  };
</script>

<style scoped>

.theme-nature {
  background-color: #d0f4ff;
}

.custom-button {
  position:absolute;
  top:50vh;
  transform:translateY(-50%);
}
.night-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(#3a59a6, #abc3e3);
  transition: opacity 1s;
  opacity: 0;
}

.theme-nature.night .night-gradient {
  opacity: 1;
}

.night-stars {
    position: absolute;
    top: -60vh; 
    left: 0;
    width: 100%;
    height: 100%;
    transition: top 1.3s; 
  }

  .theme-nature.night .night-stars {
    top: 0;
  }

  .night-moon {
    position: absolute;
    top: -60vh; 
    left: 0;
    width: 100%;
    height: 100%;
    transition: top 1.6s; 
  }

  .theme-nature.night .night-moon {
    top: 0; 
  }
  .moon, .stars {
    position: absolute;
    top: 0;
  }
  .moon {
    width: 100vw;
  }

  .stars {
    width: 180vw;
  }

  .theme-nature .nature-landscape {
    width: 160%;
    transition: bottom 2.5s;
    bottom:-50vh;
  }

  .theme-nature.night .nature-landscape {
    bottom: -50vh;
  }

  @media screen and (max-aspect-ratio: 3/4.5) {
    .night-stars, .night-moon {
      /* Styles spécifiques pour tablettes en portrait */
    }

    .theme-nature .nature-landscape {
      bottom:-10vh;
    }
  } 

  @media screen and (min-aspect-ratio: 3/4.5) {
    .night-stars, .night-moon {
      /* Styles spécifiques pour tablettes en portrait */
    }
    .theme-nature .nature-landscape {
      bottom:-10vh;
    }
  }

  @media screen and (min-aspect-ratio: 5.3/6.5) {
    .night-stars, .night-moon {
      /* Styles spécifiques pour tablettes en portrait */
    }

    .theme-nature.night .nature-landscape {
      bottom: -100vh;
    }

    .theme-nature .nature-landscape {
      bottom:-25vh;
    }
  }

  @media screen and (min-aspect-ratio: 8/6.5) {
    .night-stars, .night-moon {
      top:-100vh;
    }

    .theme-nature .nature-landscape {
      bottom:-30vh;
      width: 100%;
    }
  }

  @media screen and (min-aspect-ratio: 16/9) {


    .night-stars, .night-moon {
      width:100%;
    }

    .theme-nature .nature-landscape {
      bottom:-50vh;
    }

    .stars {
      width: 100vw;
      top: -5vw;
    }

    .moon {
      width: 50vw;
      right:0;
    }
  }


</style>