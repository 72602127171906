<template>
  <div class="parental-gate">
    <p class="question">{{ $t('parentalGateQuestion', { num1, num2 }) }}</p>
    <div class="answers">
      <ActionButton
        v-for="(answer, index) in answers"
        :key="index"
        :label="String(answer)"
        buttonType="secondary"
        @click="validateAnswer(answer)"
      />
    </div>
  </div>
</template>

<script>
  import ActionButton from '@common-components/ActionButton.vue'

  export default {
    name: 'ParentalGate',
    components: {
      ActionButton,
    },
    data() {
      return {
        num1: 0, // First number in the addition
        num2: 0, // Second number in the addition
        correctAnswer: null, // Correct answer
        answers: [], // Answer options
      }
    },
    created() {
      this.generateQuestion() // Generate a question at initialization
    },
    methods: {
      generateQuestion() {
        // Generate two random numbers between 1 and 10
        this.num1 = Math.floor(Math.random() * 10) + 1
        this.num2 = Math.floor(Math.random() * 10) + 1

        // Calculate the correct answer
        this.correctAnswer = this.num1 + this.num2

        // Generate two unique incorrect answers
        const incorrectAnswers = new Set()
        while (incorrectAnswers.size < 2) {
          const randomAnswer = Math.floor(Math.random() * 20) + 1
          if (randomAnswer !== this.correctAnswer) {
            incorrectAnswers.add(randomAnswer)
          }
        }

        // Shuffle correct and incorrect answers
        this.answers = [...incorrectAnswers, this.correctAnswer]
          .map((answer) => String(answer)) // Ensure all answers are strings
          .sort(() => Math.random() - 0.5)
      },
      validateAnswer(selectedAnswer) {
        if (Number(selectedAnswer) === this.correctAnswer) {
          // Correct answer, emit validation event
          this.$emit('validated')
        } else {
          // Incorrect answer, regenerate question and trigger a toast
          this.generateQuestion()
          this.$emit('closePopup')
          this.$store.dispatch('triggerToast', {
            type: 'error',
            mainText: this.$t('oops'),
            secondaryText: this.$t('wrongAnswerMessage'),
          })
        }
      },
    },
  }
</script>

<style scoped>
  .parental-gate {
    text-align: center;
    padding: 20px;
  }
  .question {
    font-size: min(5vw, 20px);
    margin-bottom: min(3vw, 15px);
  }
  .answers {
    display: flex;
    flex-direction: column;
    gap: min(2vw, 10px);
  }
</style>
