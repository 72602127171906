<template>
    <div :class="['stowy-logo', 'stowy-logo-'+logoType, 'stowy-logo-'+logoPosition, ]">
        <img class="stowy-logo-img" src="@app-assets/stowy-white-border.png" />
        <Vue3Lottie 
            class="stowy-logo-lottie"
            :animationData="animationData"
            :loop="false"
            height="auto"
            :speed="1.1"
        />
    </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import animationData from '@public/resources/lottie/stowyLogoAnimatedWhite.json';

export default {
    name: "StowyLogo",
    components: {
      Vue3Lottie,
    },
    data() {
      return {
        animationData,
        //TO-DO : go Typescript
        logoType:"static", // static / animated
        logoPosition: "centered", // centered / clipped-top / hidden-top
      };
    },
    props: {
        themeMode: {
            type:String,
            default: "day" 
        },
        logoState: {
            type:String,
            default: "static", 
        },
    },
    methods: {
      updateLogo(newState) {
        switch(newState) {
            case "clipped-top": 
                this.logoType="static"; 
                this.logoPosition="clipped-top"; 
                break;
            case "animated": 
                this.logoType="animated"; 
                this.logoPosition="centered"; 
                break;
            case "hidden-top": 
                this.logoType="static"; 
                this.logoPosition="hidden-top"; 
                break;
            default:         
                console.error("error 2FZZD1, beep boop");
        }
      }
    },

    mounted() {
        this.updateLogo(this.logoState);
    },

    watch: {
        logoState(newVal) {
                this.updateLogo(newVal);
        }
    }
};
</script>

<style scoped>
.stowy-logo {
    margin: 5vh auto auto auto;
    width: 100%;
    height: auto;
    transition: top 1s;
    position:relative;
}

.stowy-logo-img, .stowy-logo-lottie {
    position:absolute;
    left: 50%; 
    transform: translate(-50%, -50%);
    transition: opacity 1s; 
    max-width:min(250px, 50vw);
}
.stowy-logo-static .stowy-logo-lottie {
    opacity:0;
}
.stowy-logo-animated .stowy-logo-img {
    opacity:0;
}
.stowy-logo-clipped-top
{
    top:5vh;
}
.stowy-logo-centered
{
    top:45vh;
}
.stowy-logo-hidden-top
{
    top:-50vh;
    margin:0;
}
</style>
