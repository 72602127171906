<!-- HomeStoriesList.vue -->
<template>
  <div class="home-stories-list">
    <!-- Loading indicator -->
    <div v-if="loading" class="loading-indicator">
      {{ $t('loadingStories') }}
    </div>

    <!-- Display stories -->
    <div v-else class="list-container">
      <div
        v-for="story in displayStories"
        :key="story.id"
        :class="{
          'story-wrapper': !(
            story.id === localActiveStoryId &&
            currentViewState === 'story-preview'
          ),
        }"
        v-show="
          currentViewState !== 'story-preview' ||
          story.id === localActiveStoryId
        "
      >
        <StoryItem
          @storyClicked="handleStoryClick"
          @storyClosed="handleStoryClosed"
          @openSettingsModal="handleOpenSettingsModal"
          :firstName="firstName"
          :storyId="story.id"
          :storyTitle="formatText(story.title[gender])"
          :storyDescription="story.description"
          :storyVersions="story.versions"
          :storyObjectives="story.objectives"
          :currentViewState="currentViewState"
          :isFree="story.free"
          :isFreeForAnon="story.anon"
          :isActive="story.id === localActiveStoryId"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import StoryItem from '@app-components/StoryItem.vue'
  import { formatText } from '@app/helpers/format.js'
  import { mapActions } from 'vuex'

  export default {
    name: 'HomeStoriesList',
    components: { StoryItem },
    emits: [
      'storyClicked',
      'storyClosed',
      'openSettingsModal',
      'update:currentViewState',
    ],
    props: {
      universe: { type: String, default: '' },
      firstName: { type: String, default: '' },
      gender: { type: String, default: '' },
      currentViewState: { type: String, default: '' },
    },
    data() {
      return {
        stories: [],
        universeData: {},
        loading: true,
        localActiveStoryId: null, // Active story ID
        revealedCount: 0, // Number of stories revealed progressively
        revealInterval: null,
      }
    },
    async created() {
      try {
        // Fetch universes list
        const data = await this.$store.dispatch(
          'fetchUniversesList',
          this.$i18n.locale
        )
        this.universeData = JSON.parse(data)
      } catch (error) {
        console.error('Failed to fetch universes list:', error)
      }

      const universeStories =
        (this.universeData[this.universe] &&
          this.universeData[this.universe].stories) ||
        []

      try {
        // Fetch metadata for each story
        const storyPromises = universeStories.map((storyId) =>
          this.$store
            .dispatch('fetchStoryMetaData', {
              locale: this.$i18n.locale,
              storyId,
            })
            .then((data) => {
              const story = JSON.parse(data)
              story.free = story.free || false
              return story
            })
            .catch((error) => {
              console.error('Failed to fetch story meta data:', error)
              return null
            })
        )
        const loadedStories = await Promise.all(storyPromises)
        this.stories = loadedStories.filter((story) => story !== null)
      } catch (error) {
        console.error('Error loading stories:', error)
      }

      // Wait for all story images to load before displaying the cards
      try {
        await Promise.all(
          this.stories.map((story) => {
            return new Promise((resolve) => {
              let imageSrc = ''
              try {
                imageSrc = require(
                  `@app-assets/stories/${story.id}/story_${story.id}_thumbnail.png`
                )
              } catch (err) {
                console.error(
                  `Failed to load image for story ${story.id}:`,
                  err
                )
                resolve()
                return
              }
              const img = new Image()
              img.src = imageSrc
              img.onload = resolve
              img.onerror = resolve // Resolve even if an error occurs to prevent blocking
            })
          })
        )
      } catch (error) {
        console.error('Error loading images:', error)
      }

      this.loading = false

      // Start reveal animation if not in preview mode
      if (this.currentViewState !== 'story-preview') {
        this.startRevealAnimation()
      }
    },
    computed: {
      processedStories() {
        if (!this.stories || this.stories.length === 0) return []
        return this.stories
          .map((story) => {
            if (!story) return null
            const newStory = { ...story }
            if (newStory.title && newStory.title[this.gender]) {
              newStory.title[this.gender] = newStory.title[this.gender].replace(
                /{{first_name}}/g,
                this.firstName
              )
            }
            newStory.versions = Object.entries(story.versions || {}).map(
              ([name, versionDetails]) => ({ name, ...versionDetails })
            )
            newStory.objectives = story.objectives || {}
            return newStory
          })
          .filter((story) => story) // Filter out null values
      },
      displayStories() {
        return this.processedStories.slice(0, this.revealedCount)
      },
    },
    methods: {
      ...mapActions(['logEvent']),
      handleStoryClick(storyId) {
        if (!storyId) return

        this.localActiveStoryId = storyId

        // Switch directly to preview mode
        if (this.currentViewState !== 'story-preview') {
          this.$emit('update:currentViewState', 'story-preview')
        }

        this.$nextTick(() => {
          this.$emit('storyClicked', storyId)
          this.logEvent({
            eventName: 'open_story_summary',
            params: { story_id: storyId },
          })
        })
      },
      handleStoryClosed() {
        this.localActiveStoryId = null
        this.$emit('update:currentViewState', '') // Switch back to list mode
        this.$emit('storyClosed')
      },
      handleOpenSettingsModal() {
        this.$emit('openSettingsModal')
      },
      formatText,
      startRevealAnimation() {
        const total = this.processedStories.length
        if (total === 0) return
        this.revealInterval = setInterval(() => {
          this.revealedCount++
          if (this.revealedCount >= total) {
            clearInterval(this.revealInterval)
          }
        }, 50)
      },
    },
  }
</script>

<style scoped>
  .home-stories-list {
    display: flex;
    flex-direction: column;
  }

  .list-container {
    display: flex;
    flex-direction: column;
  }

  .story-wrapper {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.5s forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .loading-indicator {
    text-align: center;
    padding: 20px;
    font-size: 1.2em;
  }
</style>
