<template>
  <div class="modal">
    <div class="theme-container">
      <ThemeYellow />
    </div>
    <div class="content-container">
      <!-- NavigationOverlay with dynamic properties -->
      <NavigationOverlay
        v-bind="{ ...navOverlayProps }"
        @closeBtnClicked="handleCloseBtnClicked"
      />
      <div class="page-content">
        <!-- Parental Gate state -->
        <div v-if="modalState === 'parentalGate'">
          <ParentalGate
            :correctAnswer="generateCorrectAnswer()"
            @validated="handleParentalGateValidated"
            @closePopup="handleClosePopup"
          />
        </div>
        <!-- Main content state (after validation) -->
        <div v-else-if="modalState === 'homeSettings'">
          <section>
            <p
              style="
                text-align: center;
                color: #a880fe;
                font-size: min(30px, 6vw);
                font-weight: 600;
              "
            >
              {{ $t('goMultiKidsText1') }}
            </p>
            <ul v-if="isAnonymous" style="list-style-type: none; padding: 0">
              <li>{{ $t('goMultiKidsText2') }}</li>
              <li>{{ $t('goMultiKidsText3') }}</li>
              <li>{{ $t('goMultiKidsText4') }}</li>
            </ul>
            <ul v-else style="list-style-type: none; padding: 0">
              <li>{{ $t('goMultiKidsText2bis') }}</li>
              <li>{{ $t('goMultiKidsText3bis') }}</li>
              <li>{{ $t('goMultiKidsText4bis') }}</li>
            </ul>
          </section>
          <ActionButton
            v-if="isAnonymous"
            :label="$t('connectBtnText')"
            @click="redirectToLogin"
          />
          <ActionButton
            v-if="isAnonymous"
            :label="disconnectBtnText"
            buttonType="tertiary"
            @click="disconnectUser"
          />
          <p
            v-if="isAnonymous"
            style="text-align: center; margin-top: max(-4vw, -20px)"
          >
            {{ $t('cantBeUndoneText') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // Import components and utilities
  import ActionButton from '@common-components/ActionButton.vue'
  import ThemeYellow from '@app/themes/ThemeYellow.vue'
  import NavigationOverlay from '@app-components/NavigationOverlay.vue'
  import ParentalGate from '@app-components/ParentalGate.vue'
  import { useRouter } from 'vue-router'
  import { mapActions } from 'vuex'

  export default {
    name: 'MultiKidsModal',
    components: {
      ActionButton,
      ThemeYellow,
      NavigationOverlay,
      ParentalGate,
    },
    setup() {
      const router = useRouter()
      return { router }
    },
    data() {
      return {
        // modalState can be 'parentalGate' or 'homeSettings'
        modalState: 'parentalGate',
        isAnonymous: this.$store.state.userSession.auth.record.isAnonymous,
      }
    },
    computed: {
      disconnectBtnText() {
        return this.isAnonymous
          ? this.$t('anonymousDisconnectBtnText')
          : this.$t('disconnectBtnText')
      },
      settingsModalTitle() {
        return this.$t('anonymousSettingsModalTitle')
      },
      // NavigationOverlay properties
      navOverlayProps() {
        return {
          hasCloseButton: true,
          hasBackButton: false,
          hasFirstName: false,
          hasPageTitle: true,
          pageTitle: this.settingsModalTitle,
        }
      },
    },
    emits: ['closeModal'],
    props: {
      show: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      ...mapActions(['logout']),
      handleCloseBtnClicked() {
        this.$emit('closeModal')
      },
      async disconnectUser() {
        try {
          await this.logout()
          localStorage.clear()
          this.router.push({ name: 'AppHome' })
        } catch (error) {
          console.error('Error while disconnecting:', error)
        }
      },
      redirectToLogin() {
        this.router.push({ name: 'AppLogin' })
      },
      handleParentalGateValidated() {
        // Transition from parental gate to main content
        this.modalState = 'homeSettings'
      },
      handleClosePopup() {
        this.$emit('closeModal')
      },
      generateCorrectAnswer() {
        const num1 = Math.floor(Math.random() * 10) + 1
        const num2 = Math.floor(Math.random() * 10) + 1
        return `${num1} + ${num2} = ${num1 + num2}`
      },
    },
  }
</script>

<style scoped>
  li {
    font-size: min(22px, 4.4vw);
  }
</style>
