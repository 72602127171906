<template>
    <div ref="cloudsDialogBox" :class="['clouds-dialog-box', `clouds-clipped-${this.clipped}`]">
      <img :src="cloudsBackTopImg" class="clouds clouds-back clouds-top"/>
      <img :src="cloudsBackBottomImg" class="clouds clouds-back clouds-bottom"/>
      <img :src="cloudsMidTopImg" class="clouds clouds-mid clouds-top"/>
      <img :src="cloudsMidBottomImg" class="clouds clouds-mid clouds-bottom"/>
      <img :src="cloudsFrontTopImg" class="clouds clouds-front clouds-top"/>
      <img :src="cloudsFrontBottomImg" class= "clouds clouds-front clouds-bottom"/>
      <h1 ref="welcomeTitle" class="theme-content welcome-title">
        <TextDialogContainer 
          :key="this.content"
          :content="this.content"
        />
      </h1>
    </div>
</template>

<script>
import TextDialogContainer from "@app-components/TextDialogContainer.vue";

export default {
    name: "CloudsDialogBox",
    components: {
      TextDialogContainer
    },
    props: {
      content: {
        type: Array,
        default:() => [],
      },
      clipped: {
        type: String,
        default: 'top'
      },
    },
    data() {
      return {
        cloudsBackTopImg: require('@app-assets/clouds-back-top.png'),
        cloudsBackBottomImg: require('@app-assets/clouds-back-bottom.png'),
        cloudsFrontTopImg: require('@app-assets/clouds-front-top.png'),
        cloudsFrontBottomImg: require('@app-assets/clouds-front-bottom.png'),
        cloudsMidTopImg: require('@app-assets/clouds-mid-top.png'),
        cloudsMidBottomImg: require('@app-assets/clouds-mid-bottom.png'),
      }
    },

    methods: {
      adjustMaxHeight() {
            this.$nextTick(() => {
            const dialogBox = this.$refs.cloudsDialogBox;
            if (dialogBox) {
                const currentHeight = this.$refs.welcomeTitle.offsetHeight;
                dialogBox.style.maxHeight = `${currentHeight}px`;
            }
            });
        },
    },

    watch: {
      content() {
        this.adjustMaxHeight();
      }
    },

    mounted() {
      this.adjustMaxHeight();
    }
  };
</script>

<style scoped>

 /* DIALOG BOX */
 .clouds-dialog-box {
    position: absolute;
    width: 100%;
    transition: max-height 0.5s ease-out, transform 2s; 
    max-height: 0;
    background-color:white;
}

.clouds {
    width: 300vw;
    position:absolute;
}

.clouds-top {
    bottom:99%;
}

.clouds-bottom {
    top:100%;
}
.clouds-clipped-top {
    transform: translateY(0);
    padding-top:5vh;
}

.clouds-clipped-bottom {
    transform: translateY(calc(100vh - 100%));
}


.clouds-back {
    opacity: 0.3;
    animation: 270s linear move-clouds-horizontal;
    animation-iteration-count: infinite;
}

.clouds-mid {
    opacity: 0.5;
    animation: 180s linear move-clouds-horizontal;
    animation-iteration-count: infinite;
}

.clouds-front {
    animation: 120s linear move-clouds-horizontal;
    animation-iteration-count: infinite;
}
.welcome-title {

  margin: 0 2vw 0 2vw;

}


@keyframes move-clouds-horizontal {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes move-clouds-top-to-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
  
@keyframes move-clouds-bottom-to-top {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}  

@media screen and (min-aspect-ratio: 8/6.5) {
    .clouds {
      width:150%;
    }

    @keyframes move-clouds-horizontal {
      0% {
        transform: translateX(-25%);
      }
      50% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-25%);
      }
    }
  }
</style>

