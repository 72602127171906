
    .hair-color-white
    {
        background-color:#e4e1cd;
    }

    .hair-color-toffee-blonde
    {
        background-color:#988880;
    }

    .hair-color-ash-blonde
    {
        background-color:#f7e2ab;
    }

    .hair-color-blonde
    {
        background-color:#d5a678;
    }

    .hair-color-light-blonde
    {
        background-color:#e4b882;
    }

    .hair-color-golden-blonde
    {
        background-color:#dfa345;
    }

    .hair-color-medium-brown
    {
        background-color:#53381a;
    }

    .hair-color-brown
    {
        background-color:#6e3e18;
    }

    .hair-color-golden-brown
    {
        background-color:#9e6730;
    }

    .hair-color-medium-blonde
    {
        background-color:#f2b163;
    }

    .hair-color-copper-blonde
    {
        background-color:#cd832c;
    }

    .hair-color-mahorany-brown
    {
        background-color:#280e12;
    }

    .hair-color-black
    {
        background-color:#171111;
    }

    .skin-mory
    {
        background-color:#e9cba9;
    }

    .skin-porcelain
    {
        background-color:#EFCFB8;
    }

    .skin-pale-mory
    {
        background-color:#F7DDC4;
    }

    .skin-warm-mory
    {
        background-color:#F7E2AB;
    }

    .skin-sand
    {
        background-color:#EEC693;
    }

    .skin-rose-beige
    {
        background-color:#F0C189;
    }

    .skin-limestone{
        background-color:#E6BD91;
    }

    .skin-beige
    {
        background-color:#EDBF84;
    }

    .skin-senna
    {
        background-color:#CF9D7C;
    }

    .skin-honey
    {
        background-color:#CC9763;
    }

    .skin-band
    {
        background-color:#AB8B64;
    }

    .skin-almond
    {
        background-color:#93613C;
    }

    .skin-chestnut
    {
        background-color:#885633;
    }

    .skin-bronze
    {
        background-color:#75431E;
    }

    .skin-umber
    {
        background-color:#B0694B;
    }

    .skin-golden
    {
        background-color:#80492A;
    }

    .skin-espresso
    {
        background-color:#623A17;
    }

    .skin-deepest-almond
    {
        background-color:#301E10;
    }

    .accessories-red
    {
        background-color:#E7320B;
    }
    .accessories-blue
    {
        background-color:#0A319F;
    }
    .accessories-pink
    {
        background-color:#FD94FF;
    }

    .accessories-green
    {
        background-color:#11A225;
    }
    .accessories-purple
    {
        background-color:#AE1ED1;
    }
    .accessories-white
    {
        background-color:#F4F4F4;
    }
    .accessories-grey
    {
        background-color:#5A5C66;
    }

    .accessories-orange
    {
        background-color:#EB771C;
    }

    .eye-blue
    {
        background-color:#90BDE5;
    }

    .eye-black
    {
        background-color:#302626;
    }

    .eye-brown
    {
        background-color:#50361E;
    }

    .eye-hazel
    {
        background-color:#617C58;
    }

    .eye-amber
    {
        background-color:#b36800;
    }

    .eye-green
    {
        background-color:#337a2c;
    }
    
    .eye-grey
    {
        background-color:#778899;
    }

    .char-blue
    {
        background-color:#90BDE5 !important;
    }

    .black
    {
        background-color:#000000;
    }

    
    .white
    {
        background-color:#FFFFFF;
    }