<template>
    <div class="placeholder-container">
        <div v-for="(item, index) in content" :key="index" class="placeholder">
            <p :class="[item[1], 'welcome-title-phrase', 'welcome-title-phrase-'+(index+1)]">{{ item[0] }}</p>
        </div>
    </div>
    <div class="typed-container">
      <div v-for="(item, index) in content" :key="`typed-${index}`">
        <Typed :options="{
          startDelay: calculateStartDelay(index), 
          strings: [item[0]], 
          loop: false, 
          typeSpeed: 20, 
          showCursor: false, 
          classes: item[1] + ' welcome-title-phrase',
          autoDelete: false
        }">
        </Typed>
      </div>
    </div>

</template>

<script>

import Typed from "@common-components/Typed.vue";

export default {
    name: "TextDialogContainer",

    components: {
      Typed,
    },
    props: {
      content: {
        type: Array,
        default:() => [""],
      }
    },
    methods: {
        calculateStartDelay(index) {
            const baseDelayPerCharacter = 1000 / 23; // 1000 ms for 23 chars
            let totalDelay = 1000;
            for (let i = 0; i < index; i++) {
            totalDelay += this.content[i][0].length * baseDelayPerCharacter;
            }
            return totalDelay;
        },
    },
};

</script>

<style scoped>
.typed-container {
    position: absolute;
    top: 3vh;
    left: 0;
    width: 96vw;
    margin: 0 2vw 0 2vw;
    padding-top:5vh;
    
}
.placeholder {
    opacity: 0;
    pointer-events: none;
}

.text-blue {
  color: #4da0ff;
  font-weight: 600 !important;
}

.text-orange {
  color: #ee7d15;
  font-weight: 600 !important;
}

.text-purple {
  color: #a880fe;
  font-weight: 600 !important;

}

.welcome-title {
  position: relative;
  width: 100%; 
  color: #2d2d2d;
  font-size: min(60px, 6vw);
  line-height: 1.4;
  padding: 0 2rem;
  box-sizing: border-box; 
  display:block;
  text-align:left;
  margin: 0 2vw 0 2vw;
    position: relative;
}

.welcome-title-phrase {
    font-weight: 500;
}
.theme-content {
    margin:0;
    padding:min(30px, 6vw) min(15px, 3vw) min(15px, 3vw) min(15px, 3vw);
    text-align:center;
}

.theme-content p {
    font-size: min(30px, 6vw);
    margin: min(10px, 2vw);
    line-height: min(37px, 7.5vw);
}

.clouds-dialog-box {
  transition: max-height 0.5s ease-out;
}

</style>