<template>
  <div :class="['universe-title', universe]">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
  export default {
    name: 'UniverseTitle',
    props: {
      universe: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style scoped>
  .universe-title {
    position: relative;
    width: min(73.24vw, 375px);
    height: min(21.11vw, 108.5px);
    background-size: cover;
    margin: min(19.53vw, 100px) auto min(7.8vw, 40px) auto;
  }
  .universe-title.LIM {
    background-image: url('~@app-assets/LIM.png');
  }
  .universe-title.FDA {
    background-image: url('~@app-assets/FDA.png');
  }
  .universe-title.MPA {
    background-image: url('~@app-assets/MPA.png');
  }
  .universe-title.MTH {
    background-image: url('~@app-assets/MTH.png');
    width: min(78.12vw, 400px);
  }
  .universe-title > h1 {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
    font-size: min(5.3vw, 27.5px);
    color: white;
    text-shadow: 2px 2px 4px #573c27;
    line-height: min(5.86vw, 30px);
  }
  .universe-title.FDA > h1,
  .universe-title.MPA > h1 {
    width: 80%;
    transform: translate(-50%, 0);
  }
  .universe-title.MTH > h1 {
    transform: translate(-50%, -45%);
  }
</style>
