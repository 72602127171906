<template>
  <div class="universe-picker">
    <div class="carousel">
      <transition :name="transitionName">
        <div
          :key="currentImage"
          class="carousel-image"
          v-touch:swipe.left="nextImage"
          v-touch:swipe.right="previousImage"
        >
          <img :src="currentImagePath" alt="Universe Image" />
        </div>
      </transition>
      <div class="bottom-carousel">
        <ActionButton
          :label="currentText"
          buttonType="primary"
          class="tilt title-button"
          @click="selectUniverse"
        />
        <div class="carousel-indicators">
          <span
            v-for="(item, index) in items"
            :key="index"
            :class="{ active: currentIndex === index }"
            class="indicator"
            @click="setIndex(index)"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ActionButton from '@common-components/ActionButton.vue'
  import networkStatusMixin from '../mixins/networkStatusMixin'
  import { mapActions } from 'vuex'

  export default {
    name: 'UniversePicker',
    mixins: [networkStatusMixin],
    emits: ['universe-selected', 'universe-selected-change-view'],
    components: { ActionButton },
    data() {
      return {
        items: [
          {
            image: 'tlj.png',
            textKey: 'mpaUniversePickerButtonText',
            id: 'MPA',
          },
          {
            image: 'fantastique.png',
            textKey: 'mthUniversePickerButtonText',
            id: 'MTH',
          },
          {
            image: 'foret.png',
            textKey: 'fdaUniversePickerButtonText',
            id: 'FDA',
          },
          {
            image: 'pirate.png',
            textKey: 'limUniversePickerButtonText',
            id: 'LIM',
          },
        ],
        currentIndex: 0,
        direction: 'slide-left',
      }
    },
    computed: {
      currentImagePath() {
        return require(`@app-assets/${this.items[this.currentIndex].image}`)
      },
      currentText() {
        return this.items[this.currentIndex]
          ? this.getText(this.items[this.currentIndex])
          : ''
      },
      currentImage() {
        return this.items[this.currentIndex].image
      },
      transitionName() {
        return this.direction
      },
    },
    methods: {
      ...mapActions(['logEvent']),
      nextImage() {
        this.currentIndex = (this.currentIndex + 1) % this.items.length
        this.direction = 'slide-left'
      },
      previousImage() {
        this.currentIndex =
          (this.currentIndex - 1 + this.items.length) % this.items.length
        this.direction = 'slide-right'
      },
      setIndex(index) {
        this.direction =
          index > this.currentIndex ? 'slide-left' : 'slide-right'
        this.currentIndex = index
      },
      async selectUniverse() {
        if (await this.checkNetworkStatus()) {
          const selectedItem = this.items[this.currentIndex]
          if (selectedItem) {
            this.$emit('universe-selected', selectedItem.id)
            this.$emit('universe-selected-change-view')
            this.logEvent({
              eventName: 'open_universe',
              params: { universe_id: selectedItem.id },
            })
          }
        } else {
          this.showNoConnectivityWarning()
        }
      },
      getText(item) {
        return this.$t(item.textKey)
      },
      showNoConnectivityWarning() {
        this.$store.dispatch('triggerToast', {
          type: 'error',
          mainText: this.$t('oops'),
          secondaryText: this.$t('noConnectivityWarningMessage'),
        })
      },
    },
  }
</script>

<style scoped>
  .slide-left-enter-active,
  .slide-right-leave-active,
  .slide-right-enter-active,
  .slide-left-leave-active {
    transition: all 0.3s ease;
  }
  .slide-left-enter-from,
  .slide-right-leave-to {
    transform: translateX(100%);
  }
  .slide-right-enter-from,
  .slide-left-leave-to {
    transform: translateX(-100%);
  }
  .carousel-image {
    height: min(73.24vw, 375px);
    text-align: center;
    position: absolute;
    top: min(33vw, 165px);
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .carousel-image > img {
    height: inherit;
  }
  .indicator {
    display: inline-block;
    width: min(6.84vw, 35px);
    height: min(6.84vw, 35px);
    border-radius: 50%;
    background: #e0e0e0;
    margin: 0 min(1.56vw, 8px);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
  }
  .indicator.active {
    background: radial-gradient(
      92.03% 92.27% at 112.87% -0.11%,
      #84c9fd 0%,
      #7a4fd4 100%
    );
    width: min(8.56vw, 43.5px);
    height: min(8.56vw, 43.5px);
    position: relative;
    top: min(0.49vw, 2.5px);
  }
  .carousel-indicators {
    text-align: center;
    margin: auto;
    background-color: #fff;
    border-radius: min(4.88vw, 25px);
    width: fit-content;
    padding: 0;
    margin-top: min(6.84vw, 35px);
    margin-bottom: min(3.91vw, 20px);
  }
  .bottom-carousel {
    padding-top: min(107.5vw, 550px);
  }
</style>
