<template>
  <div class="item-container">
    <div class="switch-container">
      <div
        class="switch hair-switch"
        v-if="
          currentViewState === 'character-hair-shape' ||
          currentViewState === 'character-hair-color'
        "
      >
        <div
          class="switch-part"
          :class="{ active: currentViewState === 'character-hair-color' }"
          @click="changeViewState('character-hair-color')"
        >
          {{ $t('characterSwitchColor') }}
        </div>
        <div
          class="switch-part"
          :class="{ active: currentViewState === 'character-hair-shape' }"
          @click="changeViewState('character-hair-shape')"
        >
          {{ $t('characterSwitchHaircut') }}
        </div>
      </div>
      <div
        class="switch eyes-switch"
        v-if="
          currentViewState === 'character-eye-shape' ||
          currentViewState === 'character-eye-color'
        "
      >
        <div
          class="switch-part"
          :class="{ active: currentViewState === 'character-eye-color' }"
          @click="changeViewState('character-eye-color')"
        >
          {{ $t('characterSwitchColor') }}
        </div>
        <div
          class="switch-part"
          :class="{ active: currentViewState === 'character-eye-shape' }"
          @click="changeViewState('character-eye-shape')"
        >
          {{ $t('characterSwitchShape') }}
        </div>
      </div>
      <div
        class="switch glasses-switch"
        v-if="
          currentViewState === 'character-glasses-shape' ||
          currentViewState === 'character-glasses-color'
        "
      >
        <div
          class="switch-part"
          :class="{ active: currentViewState === 'character-glasses-shape' }"
          @click="changeViewState('character-glasses-shape')"
        >
          {{ $t('characterSwitchShape') }}
        </div>
        <div
          class="switch-part"
          :class="{ active: currentViewState === 'character-glasses-color' }"
          @click="changeViewState('character-glasses-color')"
        >
          {{ $t('characterSwitchColor') }}
        </div>
      </div>
    </div>
    <div class="selection-container">
      <div class="gradient-overlay" v-if="showGradient"></div>
      <div class="scroll-container" ref="scrollContainer" @scroll="checkScroll">
        <div
          class="item"
          v-for="(item, index) in itemsToDisplay"
          :key="index"
          @click="item.function"
          :class="{ active: item.isActive }"
        >
          <div class="item-name" v-if="item.name">{{ item.name }}</div>
          <div
            v-if="
              currentViewState === 'character-skin' ||
              currentViewState === 'character-hair-color' ||
              currentViewState === 'character-glasses-color' ||
              currentViewState === 'character-eye-color'
            "
            :class="['masked-item', item.class]"
          ></div>
          <img v-else :src="item.imagePath" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ItemSelector',
    props: {
      currentViewState: {
        type: String,
        default: '',
      },
      currentCharacter: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        skinColors: [
          'skin-mory',
          'skin-porcelain',
          'skin-pale-mory',
          'skin-warm-mory',
          'skin-sand',
          'skin-rose-beige',
          'skin-limestone',
          'skin-beige',
          'skin-senna',
          'skin-honey',
          'skin-band',
          'skin-almond',
          'skin-chestnut',
          'skin-bronze',
          'skin-umber',
          'skin-golden',
          'skin-espresso',
          'skin-deepest-almond',
        ],

        eyeColors: [
          'eye-blue',
          'eye-black',
          'eye-brown',
          'eye-hazel',
          'eye-amber',
          'eye-green',
          'eye-grey',
        ],

        hairColors: [
          'hair-color-white',
          'hair-color-toffee-blonde',
          'hair-color-ash-blonde',
          'hair-color-blonde',
          'hair-color-light-blonde',
          'hair-color-golden-blonde',
          'hair-color-medium-brown',
          'hair-color-brown',
          'hair-color-golden-brown',
          'hair-color-medium-blonde',
          'hair-color-copper-blonde',
          'hair-color-mahorany-brown',
          'hair-color-black',
        ],

        glassesColors: [
          'accessories-red',
          'accessories-blue',
          'accessories-pink',
          'accessories-green',
          'accessories-purple',
          'accessories-white',
          'accessories-grey',
          'accessories-orange',
        ],

        showGradient: false,
      }
    },
    computed: {
      itemsToDisplay() {
        switch (this.currentViewState) {
          case 'character-style':
            return [
              {
                imagePath: require('@app-assets/item-selector/hair/hair_25_face_choice.png'),
                function: () => this.changeViewState('character-skin'),
                name: this.$t('characterSkinText'),
              },
              {
                imagePath: require('@app-assets/item-selector/eye/eye_3_face_choice.png'),
                function: () => this.changeViewState('character-eye-color'),
                name: this.$t('characterEyesText'),
              },
              {
                imagePath: require('@app-assets/item-selector/hair/hair_21_face_choice.png'),
                function: () => this.changeViewState('character-hair-color'),
                name: this.$t('characterHairText'),
              },
              {
                imagePath: require('@app-assets/item-selector/nose/nose_5_face_choice.png'),
                function: () => this.changeViewState('character-nose'),
                name: this.$t('characterNoseText'),
              },
              {
                imagePath: require('@app-assets/item-selector/glasses/glasses_3_face_choice.png'),
                function: () => this.changeViewState('character-glasses-shape'),
                name: this.$t('characterGlassesText'),
              },
              {
                imagePath: require('@app-assets/item-selector/eyebrow/eyebrow_2_face_choice.png'),
                function: () => this.changeViewState('character-eyebrows'),
                name: this.$t('characterEyebrowsText'),
              },
              {
                imagePath: require('@app-assets/item-selector/face/face_attributes_1_face_choice.png'),
                function: () => this.changeViewState('character-face'),
                name: this.$t('characterFaceText'),
              },
              {
                imagePath: require('@app-assets/item-selector/hat/hat_2_face_choice.png'),
                function: () => this.changeViewState('character-others'),
                name: this.$t('characterOthersText'),
              },
            ]
          case 'character-skin':
            return this.skinColors.map((skinColor) => ({
              class: skinColor,
              function: () => this.selectSkinColor(skinColor),
              isActive:
                this.currentCharacter.char &&
                this.currentCharacter.char.skin_color === skinColor,
            }))

          case 'character-eye-shape': {
            return Array.from({ length: 6 }, (_, i) => ({
              imagePath: require(
                `@app-assets/item-selector/eye/eye_${i + 1}_face_choice.png`
              ),
              function: () => this.selectEyeShape(i + 1),
              isActive:
                this.currentCharacter.char &&
                this.currentCharacter.char.eye === i + 1,
            }))
          }
          case 'character-eye-color': {
            return this.eyeColors.map((eyeColor) => ({
              class: eyeColor,
              function: () => this.selectEyeColor(eyeColor),
              isActive:
                this.currentCharacter.char &&
                this.currentCharacter.char.eye_color === eyeColor,
            }))
          }
          case 'character-hair-shape': {
            let hairOptions = []
            for (let i = 1; i <= 26; i++) {
              if (i !== 6) {
                // Excluding hair id 6 which doesn't exist
                hairOptions.push({
                  imagePath: require(
                    `@app-assets/item-selector/hair/hair_${i}_face_choice.png`
                  ),
                  function: () => this.selectHairShape(i),
                  isActive:
                    this.currentCharacter.char &&
                    this.currentCharacter.char.hair === i,
                })
              }
            }
            return hairOptions
          }

          case 'character-hair-color': {
            return this.hairColors.map((hairColor) => ({
              class: hairColor,
              function: () => this.selectHairColor(hairColor),
              isActive:
                this.currentCharacter.char &&
                this.currentCharacter.char.hair_color === hairColor,
            }))
          }

          case 'character-nose': {
            return Array.from({ length: 5 }, (_, i) => ({
              imagePath: require(
                `@app-assets/item-selector/nose/nose_${i + 1}_face_choice.png`
              ),
              function: () => this.selectNoseShape(i + 1),
              isActive:
                this.currentCharacter.char &&
                this.currentCharacter.char.nose === i + 1,
            }))
          }

          case 'character-glasses-color': {
            return this.glassesColors.map((glassesColor) => ({
              class: glassesColor,
              function: () => this.selectGlassesColor(glassesColor),
              isActive:
                this.currentCharacter.char &&
                this.currentCharacter.char.glasses_color === glassesColor,
            }))
          }

          case 'character-glasses-shape': {
            let glassesOptions = []
            glassesOptions.push({
              imagePath: require(
                `@app-assets/item-selector/hair/hair_25_face_choice.png`
              ),
              function: () => this.removeGlasses(),
              isActive:
                this.currentCharacter.char &&
                !this.currentCharacter.char.glasses,
            })
            for (let i = 1; i <= 7; i++) {
              if (i !== 2) {
                // Exclure l'id de lunettes 2 s'il n'existe pas.
                glassesOptions.push({
                  imagePath: require(
                    `@app-assets/item-selector/glasses/glasses_${i}_face_choice.png`
                  ),
                  function: () => this.selectGlassesShape(i),
                  isActive:
                    this.currentCharacter.char &&
                    this.currentCharacter.char.glasses === i,
                })
              }
            }

            return glassesOptions
          }

          case 'character-eyebrows': {
            let eyebrowOptions = []
            eyebrowOptions.push({
              imagePath: require(
                `@app-assets/item-selector/hair/hair_25_face_choice.png`
              ),
              function: () => this.removeEyebrow(),
              isActive:
                this.currentCharacter.char &&
                !this.currentCharacter.char.eyebrow,
            })
            for (let i = 1; i <= 6; i++) {
              eyebrowOptions.push({
                imagePath: require(
                  `@app-assets/item-selector/eyebrow/eyebrow_${i}_face_choice.png`
                ),
                function: () => this.selectEyebrowShape(i),
                isActive:
                  this.currentCharacter.char &&
                  this.currentCharacter.char.eyebrow === i,
              })
            }
            return eyebrowOptions
          }

          case 'character-face': {
            let faceAttributesOptions = []
            for (let i = 1; i <= 3; i++) {
              faceAttributesOptions.push({
                imagePath: require(
                  `@app-assets/item-selector/face/face_attributes_${i}_face_choice.png`
                ),
                function: () => this.selectFaceAttribute(i),
                isActive:
                  this.currentCharacter.char &&
                  this.currentCharacter.char.face_attributes === i,
              })
            }
            faceAttributesOptions.push({
              imagePath: require(
                `@app-assets/item-selector/hair/hair_25_face_choice.png`
              ),
              function: () => this.removeFaceAttributes(),
              isActive:
                this.currentCharacter.char &&
                !this.currentCharacter.char.face_attributes,
            })
            return faceAttributesOptions
          }

          case 'character-others': {
            let otherOptions = []
            otherOptions.push({
              imagePath: require(
                `@app-assets/item-selector/hair/hair_25_face_choice.png`
              ),
              function: () => this.removeOthers(),
              isActive:
                this.currentCharacter.char && !this.currentCharacter.char.hat,
            })
            for (let i = 1; i <= 6; i++) {
              if (i !== 1 && i !== 3 && i !== 5) {
                // Exclure les ids spécifiés s'ils n'existent pas.
                otherOptions.push({
                  imagePath: require(
                    `@app-assets/item-selector/hat/hat_${i}_face_choice.png`
                  ),
                  function: () => this.selectOthers(i),
                  isActive:
                    this.currentCharacter.char &&
                    this.currentCharacter.char.hat === i,
                })
              }
            }

            return otherOptions
          }
          default:
            return []
        }
      },
    },
    methods: {
      changeViewState(newViewState) {
        this.$emit('view-state-changed', newViewState)
      },
      selectSkinColor(skinColor) {
        this.$emit('skin-color-selected', skinColor)
      },
      selectEyeShape(eyeShapeId) {
        this.$emit('eye-shape-selected', eyeShapeId)
      },
      selectEyeColor(eyeColor) {
        this.$emit('eye-color-selected', eyeColor)
      },
      selectHairShape(hairShapeId) {
        this.$emit('hair-shape-selected', hairShapeId)
      },
      selectHairColor(hairColor) {
        this.$emit('hair-color-selected', hairColor)
      },
      selectNoseShape(noseId) {
        this.$emit('nose-shape-selected', noseId)
      },
      selectGlassesShape(glassesId) {
        this.$emit('glasses-shape-selected', glassesId)
      },
      selectGlassesColor(glassesColor) {
        this.$emit('glasses-color-selected', glassesColor)
      },
      removeGlasses() {
        this.selectGlassesShape(null)
      },
      selectEyebrowShape(eyebrowId) {
        this.$emit('eyebrow-selected', eyebrowId)
      },
      removeEyebrow() {
        this.selectEyebrowShape(null)
      },
      selectFaceAttribute(faceAttributesId) {
        this.$emit('face-attributes-selected', faceAttributesId)
      },
      removeFaceAttributes() {
        this.selectFaceAttribute(null)
      },
      selectOthers(hatId) {
        this.$emit('hat-selected', hatId)
      },
      removeOthers() {
        this.selectOthers(null)
      },
      checkScroll() {
        this.$nextTick(() => {
          const scrollContainer = this.$refs.scrollContainer
          if (scrollContainer && scrollContainer.scrollTop > 0) {
            this.showGradient = true
          } else {
            this.showGradient = false
          }
        })
      },
    },
  }
</script>

<style scoped>
  .item-container {
    border-radius: min(2.93vw, 15px);
    padding: 20px;
    cursor: pointer;

    background-color: white;
  }

  .scroll-container {
    max-height: 40vh;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-gap: 10px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    position: relative;
  }
  @media (min-width: 768px) {
    .scroll-container {
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    }
  }
  .selection-container {
    position: relative;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    position: relative;
  }

  .item.active {
    filter: drop-shadow(3px 0 1px rgba(168, 128, 254, 0.7))
      drop-shadow(-0 3px 1px rgba(168, 128, 254, 0.7))
      drop-shadow(-3px -0 1px rgba(168, 128, 254, 0.7))
      drop-shadow(0 -3px 1px rgba(168, 128, 254, 0.7));
  }

  .item-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: min(-1vw, -5px);
  }

  .item-name {
    font-weight: 800;
    font-size: min(3.91vw, 10px);
    text-align: center;
  }

  .masked-item {
    width: 80%;
    height: 80%;
    background-size: cover;
    display: block;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('~@app-assets/item-selector/ellipse.png');
    mask-image: url('~@app-assets/item-selector/ellipse.png');
  }
  .switch-container {
    position: relative;
  }
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    background-image: linear-gradient(to bottom, white, transparent);
    z-index: 2;
    pointer-events: none;
  }

  .switch {
    display: flex;
    grid-column: 1 / -1;
    width: 100%;
    font-size: min(4.9vw, 25px);
    font-weight: 600;
    color: #d0a975;
    position: relative;
  }

  .switch-part.active {
    color: #a880fe;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: #a880fe;
  }

  .switch-part {
    text-align: center;
    flex: 1;
    width: 50%;
  }
  .scroll-container::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }

  .scroll-container::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 5px;
  }

  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #a880fe;
    border-radius: 5px;
    border: 2px solid #f9f9f9;
  }
</style>
