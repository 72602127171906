
  .slide-left-enter-active,
  .slide-right-leave-active,
  .slide-right-enter-active,
  .slide-left-leave-active {
    transition: all 0.3s ease;
  }
  .slide-left-enter-from,
  .slide-right-leave-to {
    transform: translateX(100%);
  }
  .slide-right-enter-from,
  .slide-left-leave-to {
    transform: translateX(-100%);
  }
  .carousel-image {
    height: min(73.24vw, 375px);
    text-align: center;
    position: absolute;
    top: min(33vw, 165px);
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .carousel-image > img {
    height: inherit;
  }
  .indicator {
    display: inline-block;
    width: min(6.84vw, 35px);
    height: min(6.84vw, 35px);
    border-radius: 50%;
    background: #e0e0e0;
    margin: 0 min(1.56vw, 8px);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
  }
  .indicator.active {
    background: radial-gradient(
      92.03% 92.27% at 112.87% -0.11%,
      #84c9fd 0%,
      #7a4fd4 100%
    );
    width: min(8.56vw, 43.5px);
    height: min(8.56vw, 43.5px);
    position: relative;
    top: min(0.49vw, 2.5px);
  }
  .carousel-indicators {
    text-align: center;
    margin: auto;
    background-color: #fff;
    border-radius: min(4.88vw, 25px);
    width: fit-content;
    padding: 0;
    margin-top: min(6.84vw, 35px);
    margin-bottom: min(3.91vw, 20px);
  }
  .bottom-carousel {
    padding-top: min(107.5vw, 550px);
  }
