<!-- PassportModal.vue -->
<template>
    <div class="modal">
        <div class="theme-container">
            <ThemeYellow/>
        </div>
        <div class="content-container">
            <NavigationOverlay
              :hasCloseButton="false"
              :hasBackButton="false"
              :hasFirstName="false"
              :hasPageTitle="true"
              :pageTitle="$t('passportModalTitle')"
            />
            <div class="page-content">
                <div class="form-container">
                  <FormField 
                    :placeholder="$t('firstNamePrompt')"
                    v-model="firstName"
                    :hasError="firstNameHasError"
                    :validationMessage="firstNameValidationMessage"
                    @input="firstName = $event"                
                  />
                  <div class="radio-container">
                    <TextRadioButton
                      v-for="option in options"
                      :key="option.id"
                      :value="option.value"
                      :hasError="genderSelectionHasError"
                      :validationMessage="genderSelectionValidationMessage"
                      v-model="selectedGender"
                      class="tilted"
                    >
                      {{ option.text }}
                    </TextRadioButton>
                  </div>
                  <div v-if="genderSelectionHasError" class="validation-zone">
                    <img src="@app-assets/fa-flag.png" alt="Warning Flag" class="flag" />
                    <p class="validation-message">
                      {{ genderSelectionValidationMessage }}
                    </p>
                  </div>
                </div>
                <div class="bottom-nav">
                  <ActionButton
                    :label="$t('passportValidationButtonText')"
                    buttonType="primary"
                    @click="passportModification"
                  />
                  <ActionButton
                    v-if="isCharacterDeletable"
                    :label="$t('deleteCharacterButtonText')"
                    buttonType="tertiary"
                    @click="deleteCharacter"
                  />
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

  import ThemeYellow from "@app/themes/ThemeYellow.vue";
  import { mapActions } from 'vuex';

  import FormField from "@common-components/FormField.vue";
  import ActionButton from "@common-components/ActionButton.vue";
  import TextRadioButton from './TextRadioButton.vue';
  import NavigationOverlay from "@app-components/NavigationOverlay.vue";

  export default {
    name: 'PassportModal',
    components: {
        ThemeYellow, 
        FormField,
        ActionButton,
        TextRadioButton,
        NavigationOverlay,
    },

    emits: [
      'updateCharacter',
      'closeModal',
      'deleteCharacter',
    ],


    data() {
      return {
        hasCloseButton: false,
        passportTitle: this.$t('passportModalTitle'),
        firstName: "",
        firstNameHasError: false,
        firstNameValidationMessage: this.$t('defaultFirstNameError'),
        genderSelectionHasError:false,
        genderSelectionValidationMessage: this.$t('emptyGenderSelectionError'),
        selectedGender: "M",
        options: [
          { id: 1, value: 'M', text: this.$t('heText') },
          { id: 2, value: 'F', text: this.$t('sheText') },
        ],
      }
    },

    
    mounted() {
        this.firstName = this.fbFirstName;
        this.selectedGender = this.fbGender;
    },

    computed: {
      isCharacterDeletable() {
        return this.$store.state.userSession.profile && this.$store.state.userSession.profile.charactersList && this.$store.state.userSession.profile.charactersList.length>1
      },
      fbFirstName() {
        return this.$store.state.userSession.profile.charactersList[this.$store.state.userSession.profile.lastActiveCharacter].char.firstName;
      },
      fbGender() {
        return this.$store.state.userSession.profile.charactersList[this.$store.state.userSession.profile.lastActiveCharacter].char.gender;
      }

    },

    methods: {
      ...mapActions(['logEvent']),

      closeModal() {
        this.$emit('closeModal', this.firstName);
      },

      passportModification() {
        this.validateFirstName();
        this.validateGenderSelection();
        if(!this.firstNameHasError && !this.genderSelectionHasError){
            this.setPassportInfo();
            this.closeModal();
        }
      },

      validateGenderSelection() {
        if (!this.selectedGender) {
          this.genderSelectionHasError = true;
        } else {
          this.genderSelectionHasError = false;
        }
      },

      setPassportInfo() {
        console.log("Storing first name in local storage and updating it to firebase.")
        this.logEvent({
          eventName: 'enter_name_gender',
          params: { 
            first_name: this.firstName, 
            gender: this.selectedGender,
          }
        });
        this.$emit('updateCharacter', { firstName: this.firstName, gender: this.selectedGender });

      },

      formatName(name) {
        return name
          .toLowerCase()
          .split(/\s+/) 
          .map(word => 
            word.split('-') 
              .map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1)) 
              .join('-') 
          )
          .join(' '); 
      },


      validateFirstName() {
        this.firstName = this.formatName(this.firstName);

        if(this.firstName.length<1) {
            this.firstNameValidationMessage= this.$t('emptyFirstNameError');
            this.firstNameHasError=true;
        } else {
            //Check if first Name doesn't have any special characters
            const re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
            const specialCharacterCheck = this.firstName.match(re);
            if(specialCharacterCheck) {
                this.firstNameHasError=false;
            }
            else {
                this.firstNameValidationMessage = this.$t("incorrectFirstNameError");
                this.firstNameHasError = true;
            }
        }
      },

      deleteCharacter() {
        this.$emit('deleteCharacter');
      }
    }
  }
  </script>

  <style scoped>
    .validation-zone {
        margin-top: 10px; 
        display:flex;
        align-items:center;
    }

    .validation-message {
        color: #D55E73; 
        margin: min(-0.2vw,-1px) 0 min(-0.2vw,-1px) min(1.47vw, 7px);
    } 
    .flag {
      width: min(2.24vw, 12px);
      height: min(2.34vw, 12px);
    }

</style>