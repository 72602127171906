<!-- NavigationOverlay.vue -->
<template>
  <div
    v-if="hasBackButton || hasPageTitle || hasCloseButton"
    class="navigation-overlay"
  >
    <div
      v-if="hasBackButton"
      class="back-btn button-style"
      @click="backBtnClicked"
    ></div>
    <div class="page-title">
      <h1 v-if="hasPageTitle">
        {{ pageTitle }}
        <span
          v-if="hasFirstName"
          class="surname-btn"
          @click="firstNameBtnClicked"
        >
          {{ firstName }}
          <img
            class="first-name-edit-icon"
            src="@app-assets/fa-pen-to-square.svg"
            alt="Edit pen"
          />
        </span>
      </h1>
    </div>
    <div
      v-if="hasCloseButton"
      @click="closeBtnClicked"
      :class="[
        'close-btn',
        'button-style',
        { 'night-mode': this.themeMode === 'night' },
      ]"
    ></div>
  </div>
</template>

<script>
  export default {
    name: 'NavigationOverlay',
    emits: ['backBtnClicked', 'closeBtnClicked', 'firstNameBtnClicked'],

    props: {
      hasCloseButton: {
        type: Boolean,
        default: false,
      },
      hasBackButton: {
        type: Boolean,
        default: false,
      },
      hasFirstName: {
        type: Boolean,
        default: false,
      },
      firstName: {
        type: String,
        default: '',
      },
      hasPageTitle: {
        type: Boolean,
        default: false,
      },
      pageTitle: {
        type: String,
        default: '',
      },
      themeMode: {
        type: String,
        default: 'day',
      },
    },

    methods: {
      backBtnClicked() {
        this.$emit('backBtnClicked')
      },
      closeBtnClicked() {
        this.$emit('closeBtnClicked')
      },
      firstNameBtnClicked() {
        this.$emit('firstNameBtnClicked')
      },
    },
  }
</script>

<style scoped>
  .page-title {
    padding-top: min(4.88vw, 25px);
    transform: skewY(-1deg) translateX(-50%);
    position: absolute;
    display: inline-flex;
    text-align: center;
    width: max-content;
    top: min(8vw, 40px);
    left: 50%;
    z-index: 10000;
  }

  .page-title > * {
    transform: skewY(1deg);
  }

  .button-style {
    position: absolute;
    z-index: 10001;
    cursor: pointer;
  }

  .back-btn {
    -webkit-mask: url('~@app-assets/fa-chevron.svg') no-repeat center;
    mask: url('~@app-assets/fa-chevron.svg') no-repeat center;
    left: min(12vw, 60px);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background-color: #43474f;
    width: min(4.68vw, 24px);
    height: min(7.47vw, 38px);
    top: min(18vw, 90px);
  }

  .close-btn {
    -webkit-mask: url('~@app-assets/fa-xmark.svg') no-repeat;
    mask: url('~@app-assets/fa-xmark.svg') no-repeat;
    right: min(8.79vw, 45px);
    background-color: #43474f;
    width: min(4.39vw, 22.5px);
    height: min(5.85vw, 30px);
    z-index: 10000;
    mask-size: cover;
    top: min(14vw, 70px);
  }

  .close-btn.night-mode {
    background-color: white;
  }
</style>
