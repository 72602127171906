<!-- ThemeYellow.vue -->
<template>
    <div class="theme theme-yellow">
      <div class="theme-header">
        <div class="separator"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ThemeYellow",
    props: {
      hasCloseButton: {
        type: Boolean,
        default: false,
      },
      hasBackButton: {
        type: Boolean,
        default: false,
      },
      pageTitle: {
        type: String,
        default: "Page Title"
      },
      firstName: {
        type: String,
        default: ""
      },
      firstNameVisible: {
        type:Boolean,
        default:false
      }
    },

    emits: [
      'closeBtnClicked',
      'firstNameBtnClicked',
      'backBtnClicked'
    ],

    methods: {
      closeBtnClicked() {
        this.$emit('closeBtnClicked');
      },
      firstNameBtnClicked() {
        this.$emit('firstNameBtnClicked');
      },
      backBtnClicked() {
        this.$emit('backBtnClicked');
      }
    }
  };
  </script>
  
  <style scoped>
  .theme-yellow {
    background: linear-gradient(180deg, #FFEFC2 0%, #FFFDEA 100%);
  }

  .theme-header {
    position:relative;
    top:min(32vw, 160px);
  }

  .separator {
    width:100%;
    height:min(2.92vw,15px);
    background: linear-gradient(180deg, #EFDAAA 0%, rgba(239, 218, 170, 0) 100%);
    transform: rotate(-1deg);
  }
  </style>
  