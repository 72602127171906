<template>
  <div class="character-wrapper">
    <BodyPart :images="bodyPartsImages" />
  </div>
</template>

<script>
  import BodyPart from './BodyPart.vue'

  const basePath = `char/HD+/black/`
  const coloredPath = `char/HD+/colored/`

  export default {
    name: 'CharacterWrapper',
    props: {
      bodyClothesPath: {
        type: String,
        default: '',
      },
      bodySkinPath: {
        type: String,
        default: '',
      },
      bodySkinShadowPath: {
        type: String,
        default: '',
      },
      bodySkinColor: {
        type: String,
        default: '',
      },
      mouthId: {
        type: Number,
        default: 0,
      },
      eyeColor: {
        type: String,
        default: '',
      },
      characterOrientation: {
        type: String,
        default: '',
      },
      eyeShapeId: {
        type: Number,
        default: 0,
      },
      noseId: {
        type: Number,
        default: 0,
      },
      eyebrowId: {
        type: Number,
        default: 0,
      },
      hairColor: {
        type: String,
        default: '',
      },
      glassesId: {
        type: Number,
        default: 0,
      },
      glassesColor: {
        type: String,
        default: '',
      },
      hairId: {
        type: Number,
        default: 0,
      },
      hatId: {
        type: Number,
        default: 0,
      },
      faceAttributesId: {
        type: Number,
        default: 0,
      },
      characterAnimationClass: String, // Permet de détecter si on est en transition
    },

    components: { BodyPart },

    data() {
      let bodyPartsImages = []

      //Body Skin
      bodyPartsImages.push({
        path: this.bodySkinPath,
        classes: [
          'body-parts',
          'body-skin',
          'colorized-assets',
          this.bodySkinColor,
        ],
        imageType: 'mask',
      })

      //Body Clothes
      bodyPartsImages.push({
        path: this.bodyClothesPath,
        classes: 'body-parts body-clothes',
        imageType: 'image',
      })

      //Ear
      bodyPartsImages.push(
        ...[
          {
            path: `${basePath}ear_out/1/ear_out_1_${this.characterOrientation}_black.svg`,
            classes: [
              'body-parts',
              'ear-out',
              'colorized-assets',
              this.bodySkinColor,
            ],
            imageType: 'mask',
          },
          {
            path: `${basePath}ear_in/1/ear_in_1_${this.characterOrientation}_black.svg`,
            classes: [
              'body-parts',
              'ear-in',
              'colorized-assets',
              'shadow',
              'black',
            ],
            imageType: 'mask',
          },
          {
            path: `${basePath}ear_trait/1/ear_trait_1_${this.characterOrientation}_black.svg`,
            classes: ['body-parts', 'ear-trait'],
            imageType: 'image',
          },
        ]
      )

      //Head White Bg
      bodyPartsImages.push({
        path: 'char/HD+/black/head/1/head_1_face_black.svg',
        classes: ['body-parts', 'body-skin', 'colorized-assets', 'white'],
        imageType: 'mask',
      })

      //Eyes
      bodyPartsImages.push(
        ...[
          {
            path:
              `${basePath}eye_iris/1/eye_iris_1_` +
              this.characterOrientation +
              `_black.svg`,
            classes: [
              'body-parts',
              'eye-iris',
              'colorized-assets',
              this.eyeColor,
            ],
            imageType: 'mask',
          },
          {
            path:
              `${basePath}eye_pupil/1/eye_pupil_1_` +
              this.characterOrientation +
              `_black.svg`,
            classes: ['body-parts', 'eye-pupil'],
            imageType: 'image',
          },
          {
            path:
              `${basePath}eye/` +
              this.eyeShapeId +
              `/eye_` +
              this.eyeShapeId +
              `_` +
              this.characterOrientation +
              `_black.svg`,
            classes: [
              'body-parts',
              'eye-shape',
              'colorized-assets',
              this.bodySkinColor,
            ],
            imageType: 'mask',
          },
          {
            path:
              `${basePath}eye_lash/` +
              this.eyeShapeId +
              `/eye_lash_` +
              this.eyeShapeId +
              '_' +
              this.characterOrientation +
              '_black.svg',
            classes: ['body-parts', 'eye-lash'],
            imageType: 'image',
          },
        ]
      )

      //Mouth
      bodyPartsImages.push({
        path:
          `${coloredPath}mouth/` +
          this.mouthId +
          `/mouth_` +
          this.mouthId +
          `_` +
          this.characterOrientation +
          `_colored.png`,
        classes: 'body-parts mouth',
        imageType: 'image',
      })

      //Nose
      if (this.characterOrientation != 'face') {
        bodyPartsImages.push({
          path:
            `${basePath}nose_skin/` +
            this.noseId +
            `/nose_skin_` +
            this.noseId +
            `_` +
            this.characterOrientation +
            `_black.svg`,
          classes: [
            'body-parts',
            'nose-skin',
            'colorized-assets',
            this.bodySkinColor,
          ],
          imageType: 'mask',
        })
      }
      bodyPartsImages.push({
        path:
          `${basePath}nose_shadow/` +
          this.noseId +
          `/nose_shadow_` +
          this.noseId +
          `_` +
          this.characterOrientation +
          `_black.svg`,
        classes: ['body-parts', 'nose-shadow'],
        imageType: 'image',
      })

      //Eyebrows
      if (this.eyebrowId != null) {
        if (this.characterOrientation != 'profile') {
          bodyPartsImages.push({
            path:
              `${basePath}eyebrow_left/` +
              this.eyebrowId +
              `/eyebrow_left_` +
              this.eyebrowId +
              `_` +
              this.characterOrientation +
              `_black.svg`,
            classes: [
              'body-parts',
              'eyebrow',
              'colorized-assets',
              this.hairColor,
            ],
            imageType: 'mask',
          })
        }
        bodyPartsImages.push({
          path:
            `${basePath}eyebrow_right/` +
            this.eyebrowId +
            `/eyebrow_right_` +
            this.eyebrowId +
            `_` +
            this.characterOrientation +
            `_black.svg`,
          classes: [
            'body-parts',
            'eyebrow',
            'colorized-assets',
            this.hairColor,
          ],
          imageType: 'mask',
        })
      }

      //Glasses
      if (this.glassesId != null) {
        //Glasses back branche + shadow, only for `tq` orientation
        if (this.characterOrientation == 'tq') {
          bodyPartsImages.push(
            ...[
              {
                path:
                  `${basePath}glasses_back_branche/` +
                  this.glassesId +
                  `/glasses_back_branche_` +
                  this.glassesId +
                  `_` +
                  this.characterOrientation +
                  `_black.svg`,
                classes: [
                  'body-parts',
                  'glasses-back-branche',
                  'colorized-assets',
                  this.glassesColor,
                ],
                imageType: 'mask',
              },
              {
                path:
                  `${basePath}glasses_back_branche/` +
                  this.glassesId +
                  `/glasses_back_branche_` +
                  this.glassesId +
                  `_` +
                  this.characterOrientation +
                  `_black.svg`,
                classes: [
                  'body-parts',
                  'glasses-back-branche-shadow',
                  'shadow',
                ],
                imageType: 'image',
              },
            ]
          )
        }
        bodyPartsImages.push(
          ...[
            {
              path:
                `${basePath}glasses_front_branche/` +
                this.glassesId +
                `/glasses_front_branche_` +
                this.glassesId +
                `_` +
                this.characterOrientation +
                `_black.svg`,
              classes: [
                'body-parts',
                'glasses-front-branche',
                'colorized-assets',
                this.glassesColor,
              ],
              imageType: 'mask',
            },
            {
              path:
                `${basePath}glasses_frame/` +
                this.glassesId +
                `/glasses_frame_` +
                this.glassesId +
                `_` +
                this.characterOrientation +
                `_black.svg`,
              classes: [
                'body-parts',
                'glasses-frame',
                'colorized-assets',
                this.glassesColor,
              ],
              imageType: 'mask',
            },
          ]
        )
      }

      //Haircut

      const haircutsData = require('@app-assets/collections/haircut.json')

      if (Object.prototype.hasOwnProperty.call(haircutsData, this.hairId)) {
        const hairData = haircutsData[this.hairId][this.characterOrientation]

        let hairBackId = hairData.hair_back !== 'none' ? hairData.hair_back : 0
        let hairMidId = hairData.hair_mid !== 'none' ? hairData.hair_mid : 0
        let hairFrontId =
          hairData.hair_front !== 'none' ? hairData.hair_front : 0

        if (hairBackId != 0) {
          bodyPartsImages.push({
            path: `${basePath}hair_back/${hairBackId}/hair_back_${hairBackId}_${this.characterOrientation}_black.svg`,
            classes: [
              'body-parts',
              'hair-back',
              'colorized-assets',
              this.hairColor,
            ],
            imageType: 'mask',
          })
          bodyPartsImages.push({
            path: `${basePath}hair_back/${hairBackId}/hair_back_${hairBackId}_${this.characterOrientation}_black.svg`,
            classes: ['body-parts', 'hair-back-shadow', 'shadow'],
            imageType: 'image',
          })
        }
        if (hairMidId != 0) {
          bodyPartsImages.push({
            path: `${basePath}hair_mid/${hairMidId}/hair_mid_${hairMidId}_${this.characterOrientation}_black.svg`,
            classes: [
              'body-parts',
              'hair-mid',
              'colorized-assets',
              this.hairColor,
            ],
            imageType: 'mask',
          })

          if (hairData.hair_mid_shadow.boo) {
            bodyPartsImages.push({
              path: `${basePath}hair_mid_shadow/${hairMidId}/hair_mid_shadow_${hairMidId}_${this.characterOrientation}_black.svg`,
              classes: ['body-parts', 'hair-mid-shadow', 'shadow'],
              imageType: 'image',
            })
          }
        }
        if (hairFrontId != 0) {
          bodyPartsImages.push({
            path: `${basePath}hair_front/${hairFrontId}/hair_front_${hairFrontId}_${this.characterOrientation}_black.svg`,
            classes: [
              'body-parts',
              'hair-front',
              'colorized-assets',
              this.hairColor,
            ],
            imageType: 'mask',
          })

          if (hairData.hair_front_shadow.boo != 'false') {
            bodyPartsImages.push({
              path: `${basePath}hair_front_shadow/${hairFrontId}/hair_front_shadow_${hairFrontId}_${this.characterOrientation}_black.svg`,
              classes: ['body-parts', 'hair-front-shadow', 'shadow'],
              imageType: 'image',
            })
          }
        }

        //Hat

        if (this.hatId && this.hatId != 0) {
          bodyPartsImages.push({
            path:
              `${coloredPath}hat/` +
              this.hatId +
              `/hat_` +
              this.hatId +
              `_` +
              this.characterOrientation +
              `_colored.png`,
            classes: 'body-parts hat',
            imageType: 'image',
          })
        }

        //Face Attributes

        if (this.faceAttributesId && this.faceAttributesId != 0) {
          bodyPartsImages.push({
            path:
              `${coloredPath}face_attributes/` +
              this.faceAttributesId +
              `/face_attributes_` +
              this.faceAttributesId +
              `_` +
              this.characterOrientation +
              `_colored.png`,
            classes: 'body-parts face-attributes',
            imageType: 'image',
          })
        }
      } else {
        console.log(`No data was found for haircut Id ${this.hairId}`)
      }

      return {
        bodyPartsImages: bodyPartsImages,
      }
    },
    watch: {
      bodySkinColor(newColor, oldColor) {
        this.bodyPartsImages = this.bodyPartsImages.map((item) => {
          if (item.classes.includes('colorized-assets')) {
            const newClasses = item.classes.map((cls) =>
              cls === oldColor ? newColor : cls
            )
            return { ...item, classes: newClasses }
          }
          return item
        })
      },
      eyeShapeId(newId) {
        this.bodyPartsImages = this.bodyPartsImages.map((image) => {
          if (image.type === 'eye-shape') {
            const newPath = image.path.replace(/eye\/\d+\//, `eye/${newId}/`)
            return { ...image, path: newPath, id: `eye-shape-${newId}` }
          }
          return image
        })
      },
      characterAnimationClass(newClass) {
        if (newClass === 'leaving') {
          this.isTransitioning = true
        } else if (newClass === 'entering') {
          setTimeout(() => {
            this.isTransitioning = false
          }, 400) // Temps d'arrêt après la transition
        }
      },
    },
  }
</script>

<style>
  .character-wrapper {
    text-align: center;
    position: relative;
    width: min(136.72vw, 700px);
    height: min(196.58vw, 1006px);
    top: min(24vw, 120px);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* ====== BODY PARTS =======*/
  .body-parts {
    position: absolute;
    width: 100%;
    top: 3%;
    left: 0;
    height: 100%;
  }
  .colorized-assets {
    -webkit-mask-size: contain !important;
    -webkit-mask-repeat: no-repeat !important;
    -webkit-mask-position: center !important;
  }
  .shadow {
    opacity: 0.03;
  }

  .hair-back {
    z-index: 5;
  }

  .hair-back-shadow {
    z-index: 6;
  }

  .body-skin {
    z-index: 15;
  }

  .body-skin-shadow {
    z-index: 16;
  }

  .body-clothes {
    z-index: 20;
  }

  .glasses-back-branche {
    z-index: 21;
  }

  .glasses-back-branche-shadow {
    z-index: 22;
  }

  .head-white-bg {
    z-index: 25;
  }

  .eye-iris {
    z-index: 26;
  }

  .eye-pupil {
    z-index: 27;
  }

  .eye-shape {
    z-index: 30;
  }

  .eye-lash {
    z-index: 31;
  }

  .glasses-front-branche {
    z-index: 35;
  }

  .hair-mid {
    z-index: 40;
  }

  .hair-mid-shadow {
    z-index: 41;
  }

  .ear-out {
    z-index: 45;
  }

  .ear-in {
    z-index: 50;
  }

  .ear-trait {
    z-index: 55;
    opacity: 0.25;
  }

  .eyebrow {
    z-index: 75;
  }

  .nose-skin {
    z-index: 80;
  }

  .nose-shadow {
    z-index: 81;
    opacity: 0.35;
  }

  .face-attributes {
    z-index: 82;
  }
  .mouth {
    z-index: 85;
  }

  .glasses-frame {
    z-index: 92;
  }

  .hair-front {
    z-index: 95;
  }

  .hair-front-shadow {
    z-index: 96;
  }

  .hat {
    z-index: 105;
  }

  .visibility-hidden {
    visibility: hidden;
  }
</style>
