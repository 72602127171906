<!-- TextRadioButton.vue -->

<template>
    <div
      :class="{ active: isSelected }"
      class="text-radio-button"
      @click="select"
    >
      <slot></slot>
    </div>
</template>
  
<script>
export default {
  props: ['value', 'modelValue'],
  data() {
    return {
      isSelected: this.value === this.modelValue
    };
  },
  watch: {
    modelValue(newVal) {
      this.isSelected = this.value === newVal;
    }
  },
  methods: {
    select() {
      this.$emit('update:modelValue', this.value);
    }
  }
};
</script>
  
<style scoped>
.text-radio-button {
  display: inline-block;
  cursor: pointer;
  color: #D0A975;
  background: #FDE5B7;
  border-radius: min(2.92vw,15px);
  font-size: min(6.34vw, 32.5px);
  margin: min(1.95vw, 10px);
  padding: min(0.49vw, 2.5px) min(3.90vw, 20px) min(0.97vw, 5px) min(3.90vw, 20px);
  position:relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-radio-button.small{
  font-size:min(3vw,15px);
}

.text-radio-button.tilted {
  transform:rotate(1deg);
}

.text-radio-button.active.tilted {
  transform:rotate(-1deg);
}

.active {
    color: #FFFFFF;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    background: linear-gradient(270deg, #7143F9 0%, #7B51D4 100%);
    font-weight:600;
}

.active::before {
    content: "";
    border-radius: min(2.93vw, 15px);
    position: absolute;
    height: 100%;
    left:0px;
    right: 0px;
    z-index: -1;
    top:min(-0.68vw, -3.5px);
    background: linear-gradient(270deg, #7B51D4 0%, #A880FE 100%);
}
</style>
