<!-- HomeStories.vue -->
<template>
  <NavigationOverlay
    :key="firstName"
    :hasCloseButton="showCloseButton"
    :hasBackButton="false"
    :hasFirstName="true"
    :firstName="firstName"
    :hasPageTitle="currentViewState === 'universes'"
    :themeMode="themeMode"
    @closeBtnClicked="closeCurrentViewState"
    @firstNameBtnClicked="redirectToCharacterChoice"
  />

  <UniversePicker
    v-if="currentViewState === 'universes'"
    :selectedUniverse="currentUniverse"
    @universe-selected="updateCurrentUniverse"
    @universe-selected-change-view="changeViewToStories"
  />

  <div
    v-if="
      currentViewState === 'stories' || currentViewState === 'story-preview'
    "
    :class="{ 'scrollable scroll-container': currentViewState === 'stories' }"
  >
    <UniverseTitle
      v-if="currentViewState === 'stories'"
      :universe="currentUniverse"
      :title="universeData[currentUniverse]?.name || ''"
    />
    <HomeStoriesList
      :key="currentUniverse"
      :universe="currentUniverse"
      :firstName="firstName"
      :gender="gender"
      :currentViewState="currentViewState"
      :activeStoryId="activeStoryId"
      @storyClicked="handleStoryClicked"
      @openSettingsModal="openSettingsModal"
    />
  </div>

  <SettingsModal
    v-if="showSettingsModal"
    @closeModal="showSettingsModal = false"
  />

  <!-- Rating modal shown if the story hasn't been rated yet -->
  <RatingModal
    v-if="showRatingModal"
    :storyId="activeStoryId"
    @closeModal="showRatingModal = false"
    @ratingSubmitted="handleRatingSubmitted"
  />

  <button class="settings-btn" @click="openSettingsModal">
    <img src="@app-assets/settings-section.png" alt="Settings button" />
  </button>
</template>

<script>
  import { useRouter } from 'vue-router'
  import { mapActions } from 'vuex'
  import SettingsModal from '@app-components/SettingsModal.vue'
  import UniversePicker from '@app-components/UniversePicker.vue'
  import HomeStoriesList from '@app-components/HomeStoriesList.vue'
  import NavigationOverlay from '@app-components/NavigationOverlay.vue'
  import UniverseTitle from '@app-components/UniverseTitle.vue'
  import RatingModal from '@app-components/RatingModal.vue'
  import networkStatusMixin from '../mixins/networkStatusMixin'

  export default {
    name: 'HomeStories',
    mixins: [networkStatusMixin],
    setup() {
      const router = useRouter()
      return { router }
    },
    components: {
      SettingsModal,
      UniversePicker,
      HomeStoriesList,
      NavigationOverlay,
      UniverseTitle,
      RatingModal,
    },
    data() {
      return {
        showSettingsModal: false,
        showRatingModal: false,
        currentViewState: 'universes',
        currentUniverse: 'LIM',
        showCloseButton: false,
        universeData: {},
        activeStoryId: null,
        theme: 'ThemeNature',
        themeMode: 'day',
        themeContent: [['']],
        logoState: 'hidden-top',
        bottomOffset: 10,
      }
    },
    async mounted() {
      try {
        const data = await this.$store.dispatch(
          'fetchUniversesList',
          this.$i18n.locale
        )
        this.universeData = JSON.parse(data)
      } catch (error) {
        console.error('Failed to fetch universes list:', error)
      }

      // Check URL parameters for "from=story" and "storyId"
      const fromQueryParam = this.$route.query.from
      const storyIdQuery = this.$route.query.storyId

      if (fromQueryParam === 'story' && storyIdQuery) {
        this.activeStoryId = storyIdQuery

        // Find the correct universe for the story by scanning universeData
        let foundUniverse = this.currentUniverse // default value
        for (const [universeKey, universeValue] of Object.entries(
          this.universeData
        )) {
          // Ensure that story IDs are compared as strings
          const storiesList = (universeValue.stories || []).map((id) =>
            id.toString()
          )
          if (storiesList.includes(storyIdQuery.toString())) {
            foundUniverse = universeKey
            break
          }
        }
        this.currentUniverse = foundUniverse

        // Check if the story has been rated by the user
        const userProfile = this.$store.state.userSession.profile
        const hasRated = userProfile?.hasRated || []
        if (!hasRated.includes(storyIdQuery.toString())) {
          // Show rating modal if not yet rated
          this.showRatingModal = true
        }

        this.changeViewToStories()

        // Log event for returning from story
        const readerData = JSON.parse(localStorage.getItem('reader'))
        if (readerData?.startTimestamp) {
          const startTimestamp = new Date(readerData.startTimestamp)
          const currentTimestamp = new Date()
          const readingSessionDuration = Math.floor(
            (currentTimestamp - startTimestamp) / 60000
          )
          this.logEvent({
            eventName: 'return_from_story',
            params: {
              story_id: storyIdQuery,
              reading_session_duration: readingSessionDuration,
            },
          })
          delete readerData.startTimestamp
          localStorage.setItem('reader', JSON.stringify(readerData))
        }
      }
    },
    computed: {
      firstName() {
        const profile = this.$store.state.userSession.profile
        if (
          profile &&
          profile.charactersList &&
          profile.charactersList.length > 0
        ) {
          const activeCharacter =
            profile.charactersList[profile.lastActiveCharacter]
          return (
            activeCharacter?.char?.firstName || this.getLocalStorageFirstName()
          )
        }
        return this.getLocalStorageFirstName()
      },
      gender() {
        const profile = this.$store.state.userSession.profile
        if (
          profile &&
          profile.charactersList &&
          profile.charactersList.length > 0
        ) {
          const activeCharacter =
            profile.charactersList[profile.lastActiveCharacter]
          return activeCharacter?.char?.gender || this.getLocalStorageGender()
        }
        return this.getLocalStorageGender()
      },
      isAnonymous() {
        return this.$store.state.userSession.auth.record.isAnonymous
      },
    },
    methods: {
      ...mapActions(['logEvent']),
      redirectToCharacterChoice() {
        this.router.push({ name: 'CharacterChoice' })
      },
      getLocalStorageFirstName() {
        const readerData = JSON.parse(localStorage.getItem('reader'))
        return readerData?.char?.firstName || 'Inconnu'
      },
      getLocalStorageGender() {
        const readerData = JSON.parse(localStorage.getItem('reader'))
        return readerData?.char?.gender || 'M'
      },
      openSettingsModal() {
        this.showSettingsModal = true
        this.logEvent({
          eventName: this.isAnonymous
            ? 'open_solo_kid_popup_anonymous'
            : 'open_settings_popup_logged_in',
        })
      },
      async updateCurrentUniverse(universe) {
        if (await this.checkNetworkStatus()) {
          this.currentUniverse = universe
        } else {
          this.showNoConnectivityWarning()
        }
      },
      async changeViewToStories() {
        if (await this.checkNetworkStatus()) {
          this.currentViewState = 'stories'
          this.showCloseButton = true
        } else {
          this.showNoConnectivityWarning()
        }
      },
      closeCurrentViewState() {
        if (this.currentViewState === 'stories') {
          this.currentViewState = 'universes'
          this.showCloseButton = false
          this.logEvent({ eventName: 'close_universe' })
        } else if (this.currentViewState === 'story-preview') {
          this.logEvent({ eventName: 'close_story_summary' })
          this.themeMode = 'day'
          this.currentViewState = 'stories'
          this.showCloseButton = true
        }
      },
      async handleStoryClicked(storyId) {
        if (await this.checkNetworkStatus()) {
          this.currentViewState = 'story-preview'
          if (storyId === 12) {
            this.themeMode = 'night'
          }
        } else {
          this.showNoConnectivityWarning()
        }
      },
      // Function called when rating modal is submitted
      handleRatingSubmitted(ratingData) {
        this.showRatingModal = false
      },
      showNoConnectivityWarning() {
        this.$store.dispatch('triggerToast', {
          type: 'error',
          mainText: this.$t('oops'),
          secondaryText: this.$t('noConnectivityWarningMessage'),
        })
      },
    },
  }
</script>

<style scoped>
  .settings-btn {
    right: 0;
    transition: transform 0.3s;
    position: absolute;
    bottom: min(4.88vw, 25px);
    padding-left: 0;
    width: min(27vw, 135px);
    height: min(28.4vw, 142px);
    z-index: 2;
  }
  .settings-btn > img {
    width: 100%;
  }
  .settings-btn:hover {
    transform: scale(1.03);
    cursor: pointer;
  }
  .scrollable {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .scrollable::-webkit-scrollbar {
    display: none;
  }
</style>
