<!-- Characterchoice.vue -->
<template>
  <NavigationOverlay
    :key="firstName"
    :hasCloseButton="false"
    :hasBackButton="hasBackButton"
    :hasFirstName="firstNameVisible"
    :firstName="firstName"
    :hasPageTitle="true"
    :pageTitle="pageTitle"
    @backBtnClicked="handleBackBtnClicked"
    @firstNameBtnClicked="handleFirstNameBtnClicked"
  />
  <div class="page-content">
    <div v-if="currentViewState == 'character-choice'" class="character-nav">
      <div
        class="float-btn add-btn left-btn"
        @click="addNewCharacter"
        v-show="showLeftAddButton"
      ></div>
      <div
        class="float-btn nav-btn left-btn"
        @click="decrementCharacterIndex"
        v-show="showLeftNavButton"
      ></div>
      <div
        class="float-btn add-btn right-btn"
        @click="addNewCharacter"
        v-show="showRightAddButton"
      ></div>
      <div
        class="float-btn nav-btn right-btn"
        @click="incrementCharacterIndex"
        v-show="showRightNavButton"
      ></div>
    </div>
    <!-- Wrap the CharacterWrapper with a transition for a smooth fade effect -->
    <CharacterWrapper
      :key="serializedCharacter"
      class="character-wrapper"
      :class="characterAnimationClass"
      :bodyClothesPath="characterChoiceBodyClothesPath"
      :bodySkinPath="characterChoiceBodySkinPath"
      :bodySkinShadowPath="characterChoiceBodySkinShadowPath"
      :bodySkinColor="currentCharacter.char.skin_color"
      :characterOrientation="characterOrientation"
      :mouthId="characterMouthId"
      :eyeColor="currentCharacter.char.eye_color"
      :eyeShapeId="currentCharacter.char.eye"
      :noseId="currentCharacter.char.nose"
      :hairColor="currentCharacter.char.hair_color"
      :eyebrowId="currentCharacter.char.eyebrow"
      :glassesId="currentCharacter.char.glasses"
      :glassesColor="currentCharacter.char.glasses_color"
      :hairId="currentCharacter.char.hair"
      :hatId="currentCharacter.char.hat"
      :faceAttributesId="currentCharacter.char.face_attributes"
    />
    <div class="bottom-nav">
      <ActionButton
        :label="$t('characterValidationButtonText')"
        buttonType="primary"
        @click="validateCharacterChoice"
        v-if="currentViewState == 'character-choice'"
      />
      <ActionButton
        :label="$t('characterModificationButtonText')"
        buttonType="secondary"
        @click="customizeCharacter"
        v-if="currentViewState == 'character-choice'"
      />
      <ItemSelector
        v-if="currentViewState != 'character-choice'"
        :currentViewState="currentViewState"
        :currentCharacter="currentCharacter"
        @view-state-changed="handleViewStateChange"
        @skin-color-selected="updateSkinColor"
        @eye-shape-selected="updateEyeShape"
        @eye-color-selected="updateEyeColor"
        @hair-shape-selected="updateHairShape"
        @hair-color-selected="updateHairColor"
        @nose-shape-selected="updateNoseShape"
        @glasses-shape-selected="updateGlassesShape"
        @glasses-color-selected="updateGlassesColor"
        @eyebrow-selected="updateEyebrow"
        @face-attributes-selected="updateFaceAttributes"
        @hat-selected="updateHat"
      />
    </div>
  </div>
  <PassportModal
    v-show="showPassport"
    :key="currentCharacter"
    @closeModal="handleClosePassportModal($event)"
    @updateCharacter="updateCharacter($event)"
    @deleteCharacter="deleteCharacter()"
  />
  <MultiKidsModal
    v-show="showMultiKids"
    @closeModal="handleCloseMultiKidsModal($event)"
  />
</template>

<script>
  import { useRouter } from 'vue-router'
  import { mapActions } from 'vuex'
  import ActionButton from '@common-components/ActionButton.vue'
  import CharacterWrapper from '@app-components/CharacterWrapper.vue'
  import PassportModal from '@app-components/PassportModal.vue'
  import ItemSelector from '@app-components/ItemSelector.vue'
  import NavigationOverlay from '@app-components/NavigationOverlay.vue'
  import MultiKidsModal from '@app-components/MultiKidsModal.vue'

  export default {
    name: 'CharacterChoice',
    setup() {
      const router = useRouter()
      return { router }
    },
    components: {
      ActionButton,
      CharacterWrapper,
      PassportModal,
      ItemSelector,
      NavigationOverlay,
      MultiKidsModal,
    },
    data() {
      return {
        theme: 'ThemeYellow',
        currentCharacter: [],
        showPassport: false,
        showMultiKids: false,
        characterChoiceBodyClothesPath: 'selector/body_clothes.png',
        characterChoiceBodySkinPath: 'selector/body_skin.svg',
        characterChoiceBodySkinShadowPath: 'selector/body_skin_shadow.svg',
        characterMouthId: 10,
        characterOrientation: 'face',
        characterAnimationClass: '', // Classe pour l'animation

        currentViewState: 'character-choice',
        firstName: '',
      }
    },

    computed: {
      pageTitle() {
        const titles = {
          'character-choice': this.$t('helloMessage'),
          'character-style': this.$t('characterStyleText'),
          'character-skin': this.$t('characterSkinText'),
          'character-eye-color': this.$t('characterEyesText'),
          'character-eye-shape': this.$t('characterEyesText'),
          'character-hair-color': this.$t('characterHairText'),
          'character-hair-shape': this.$t('characterHairText'),
          'character-nose': this.$t('characterNoseText'),
          'character-glasses-color': this.$t('characterGlassesText'),
          'character-glasses-shape': this.$t('characterGlassesText'),
          'character-eyebrows': this.$t('characterEyebrowsText'),
          'character-face': this.$t('characterFaceText'),
          'character-others': this.$t('characterOthersText'),
        }
        return titles[this.currentViewState] || 'Default Title'
      },
      userProfile() {
        return this.$store.state.userSession.profile
      },
      hasBackButton() {
        return !(this.currentViewState === 'character-choice')
      },
      firstNameVisible() {
        return this.currentViewState === 'character-choice'
      },
      serializedCharacter() {
        return JSON.stringify(this.currentCharacter)
      },
      showRightAddButton() {
        const profile = this.userProfile
        return (
          profile.lastActiveCharacter === !profile.stripeRole ||
          (profile.stripeRole && profile.stripeRole != 'multi') ||
          (profile.charactersList && profile.charactersList.length < 2) ||
          (profile.stripeRole && profile.stripeRole != 'multi') ||
          (profile.charactersList &&
            profile.lastActiveCharacter &&
            profile.lastActiveCharacter == profile.charactersList.length - 1)
        )
      },
      showLeftAddButton() {
        const profile = this.userProfile
        return (
          profile.stripeRole &&
          profile.stripeRole == 'multi' &&
          profile.lastActiveCharacter === 0 &&
          profile.charactersList.length > 1
        )
      },
      showLeftNavButton() {
        const profile = this.userProfile
        return (
          profile.stripeRole &&
          profile.stripeRole == 'multi' &&
          profile.lastActiveCharacter > 0
        )
      },
      showRightNavButton() {
        const profile = this.userProfile
        return (
          profile.stripeRole &&
          profile.stripeRole == 'multi' &&
          profile.lastActiveCharacter < profile.charactersList.length - 1
        )
      },
    },

    created() {
      this.initializeCharacterSelection()
    },
    watch: {
      currentCharacter(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.animateCharacterChange()
        }
      },
    },
    methods: {
      animateCharacterChange() {
        this.characterAnimationClass = 'leaving'
        setTimeout(() => {
          this.characterAnimationClass = 'entering'
          setTimeout(() => {
            this.characterAnimationClass = ''
          }, 400) // Durée de l'animation
        }, 200)
      },
      ...mapActions(['logEvent']),

      handleFirstNameBtnClicked() {
        this.logEvent({
          eventName: 'open_passport',
        })
        this.showPassport = true
      },

      async initializeCharacterSelection() {
        if (!this.userProfile) {
          await this.$store.dispatch('loadUserProfile')
        }

        const profile = this.userProfile

        if (
          !profile ||
          !profile.charactersList ||
          profile.charactersList.length === 0
        ) {
          const baseChar = this.createBaseCharacter()
          this.logEvent({
            eventName: 'create_character',
          })
          await this.$store.dispatch('addCharacterToList', baseChar)
        }

        if (
          typeof profile.lastActiveCharacter === 'undefined' ||
          !Number.isInteger(profile.lastActiveCharacter) ||
          profile.lastActiveCharacter >= profile.charactersList.length
        ) {
          await this.$store.dispatch('setLastActiveCharacter', 0)
        }

        await this.$store.dispatch('loadUserProfile')

        this.currentCharacter =
          this.userProfile.charactersList[this.userProfile.lastActiveCharacter]
        this.firstName = this.currentCharacter.char.firstName

        if (this.firstName == '' || this.currentCharacter.char.gender == '') {
          this.showPassport = true
        }
      },
      createBaseCharacter() {
        return {
          char: {
            firstName: '',
            gender: '',
            skin_color: 'skin-honey',
            face_attributes: null,
            hair: 21,
            hair_color: 'hair-color-brown',
            hat: null,
            eye: 1,
            eye_color: 'eye-hazel',
            eyebrow: 2,
            glasses: null,
            glasses_color: 'accessories-blue',
            nose: 5,
          },
        }
      },

      async updateCharacter(newCharacter) {
        this.currentCharacter =
          this.userProfile.charactersList[this.userProfile.lastActiveCharacter]
        this.currentCharacter.char = {
          ...this.currentCharacter.char,
          ...newCharacter,
        }
        const currentCharacterJson = JSON.stringify(this.currentCharacter)
        await this.$store.dispatch('updateCharacterInList', {
          characterIndex: this.userProfile.lastActiveCharacter,
          updatedCharacter: this.currentCharacter,
        })
        localStorage.setItem('reader', currentCharacterJson)
      },
      updateSkinColor(newSkinColor) {
        this.currentCharacter.char.skin_color = newSkinColor
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_skin_color',
          params: { skin_color_id: newSkinColor, source: 'app_' },
        })
      },
      updateEyeShape(eyeShapeId) {
        this.currentCharacter.char.eye = eyeShapeId
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_eye_shape',
          params: { eye_shape_id: eyeShapeId, source: 'app_' },
        })
      },
      updateEyeColor(eyeColor) {
        this.currentCharacter.char.eye_color = eyeColor
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_eye_color',
          params: { eye_color_id: eyeColor, source: 'app_' },
        })
      },
      updateHairColor(newHairColor) {
        this.currentCharacter.char.hair_color = newHairColor
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_hair_color',
          params: { hair_color_id: newHairColor, source: 'app_' },
        })
      },
      updateHairShape(hairShapeId) {
        this.currentCharacter.char.hair = hairShapeId
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_hair_shape',
          params: { hair_shape_id: hairShapeId, source: 'app_' },
        })
      },
      updateNoseShape(noseShapeId) {
        this.currentCharacter.char.nose = noseShapeId
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_nose_shape',
          params: { nose_id: noseShapeId, source: 'app_' },
        })
      },
      updateGlassesShape(glassesShapeId) {
        this.currentCharacter.char.glasses = glassesShapeId
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_glasses_shape',
          params: { glasses_shape_id: glassesShapeId, source: 'app_' },
        })
      },
      updateGlassesColor(glassesColor) {
        this.currentCharacter.char.glasses_color = glassesColor
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_glasses_color',
          params: { glasses_color_id: glassesColor, source: 'app_' },
        })
      },
      updateEyebrow(eyebrowId) {
        this.currentCharacter.char.eyebrow = eyebrowId
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_eyebrow',
          params: { eyebrow_id: eyebrowId, source: 'app_' },
        })
      },
      updateFaceAttributes(faceAttributesId) {
        this.currentCharacter.char.face_attributes = faceAttributesId
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_face_attributes',
          params: { face_attributes_id: faceAttributesId, source: 'app_' },
        })
      },
      updateHat(hatId) {
        this.currentCharacter.char.hat = hatId
        this.updateCharacter(this.currentCharacter.char)
        this.logEvent({
          eventName: 'update_hat',
          params: { hat_id: hatId, source: 'app_' },
        })
      },
      handleBackBtnClicked() {
        switch (this.currentViewState) {
          case 'character-style':
            this.currentViewState = 'character-choice'
            this.logEvent({
              eventName: 'back_to_character_selection',
            })
            break
          default:
            this.currentViewState = 'character-style'
            break
        }
      },
      validateCharacterChoice() {
        console.log('Character Choice Validation')
        this.logEvent({
          eventName: 'click_its_me',
        })
        this.redirectToHomeStories()
      },
      customizeCharacter() {
        this.handleViewStateChange('character-style')
        this.logEvent({
          eventName: 'click_edit_character_design',
        })
      },
      redirectToHomeStories() {
        this.router.push({
          name: 'HomeStories',
        })
      },
      handleViewStateChange(newViewState) {
        this.currentViewState = newViewState
      },
      handleClosePassportModal(newValue) {
        this.firstName = newValue
        this.showPassport = false
      },
      handleCloseMultiKidsModal(newValue) {
        this.showMultiKids = false
        this.logEvent({
          eventName: 'close_multikids_popup',
        })
      },
      showMultiModal() {
        this.showMultiKids = true
      },
      async incrementCharacterIndex() {
        if (
          this.userProfile.lastActiveCharacter <
          this.userProfile.charactersList.length - 1
        ) {
          await this.$store.dispatch(
            'setLastActiveCharacter',
            this.userProfile.lastActiveCharacter + 1
          )
          this.initializeCharacterSelection()
        }
      },
      async decrementCharacterIndex() {
        if (this.userProfile.lastActiveCharacter > 0) {
          await this.$store.dispatch(
            'setLastActiveCharacter',
            this.userProfile.lastActiveCharacter - 1
          )
          this.initializeCharacterSelection()
        }
      },
      async addNewCharacter() {
        const profile = this.userProfile
        if (!profile.stripeRole || profile.stripeRole !== 'multi') {
          this.showMultiModal()
          this.logEvent({
            eventName: 'open_multikids_popup',
          })
        } else {
          try {
            const newCharacter = this.createBaseCharacter()
            await this.$store.dispatch('addCharacterToList', newCharacter)
            await this.$store.dispatch(
              'setLastActiveCharacter',
              this.userProfile.charactersList.length - 1
            )
            this.initializeCharacterSelection()
            localStorage.setItem('reader', JSON.stringify(newCharacter))
            this.fbFirstName = ''
            this.fbGender = ''
            this.showPassport = true
            this.logEvent({
              eventName: 'create_additional_character',
            })
          } catch (error) {
            if (error.code === 'custom/too-many-characters') {
              this.$store.dispatch('triggerToast', {
                type: 'error',
                mainText: this.$t('oops'),
                secondaryText: this.$t('tooManyCharactersInfoText'),
              })
            } else {
              console.error('Error adding new character:', error.message)
            }
          }
        }
      },
      async deleteCharacter() {
        await this.$store.dispatch(
          'deleteCharacterFromList',
          this.userProfile.lastActiveCharacter
        )
        await this.$store.dispatch('setLastActiveCharacter', 0)
        this.initializeCharacterSelection()
        this.showPassport = false
        this.logEvent({
          eventName: 'delete_character',
        })
      },
    },
  }
</script>

<style scoped>
  /* Fade transition styles */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .float-btn {
    z-index: 200;
    position: absolute;
    background-color: #a880fe;
    background-size: cover;
    display: block;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    cursor: pointer;
  }

  .add-btn {
    width: min(37.5px, 7.5vw);
    height: min(37.5px, 7.5vw);
    -webkit-mask-image: url('~@app-assets/fa-circle-plus-solid.svg');
    mask-image: url('~@app-assets/fa-circle-plus-solid.svg');
  }

  .nav-btn {
    width: min(25px, 5vw);
    height: min(37.5px, 7.5vw);
    -webkit-mask-image: url('~@app-assets/fa-chevron.svg');
    mask-image: url('~@app-assets/fa-chevron.svg');
  }

  .left-btn {
    left: 5px;
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .right-btn {
    right: 5px;
  }

  .character-wrapper {
    transition:
      opacity 0.4s ease,
      transform 0.4s ease;
  }

  .leaving {
    opacity: 0;
  }

  .entering {
    opacity: 1;
  }

  /* existing styles remain unchanged */
</style>
