<template>
  <div class="modal">
    <div class="theme-container">
      <ThemeYellow />
    </div>
    <div class="content-container">
      <NavigationOverlay v-bind="{ ...navOverlayProps }" />
      <div class="page-content">
        <p class="explanation-text">
          {{ $t('rateStoryExplanationText1') }}
        </p>
        <h2 class="explanation-text">
          {{ storyTitle }}
        </h2>
        <p class="explanation-text">
          {{ $t('rateStoryExplanationText2') }}
        </p>
        <div class="rating-container">
          <div class="star-rating">
            <span
              v-for="star in 5"
              :key="star"
              class="star"
              @mouseover="setHover(star)"
              @mouseleave="clearHover"
              @click="setRating(star)"
              :style="getStarStyle(star)"
              >⭐</span
            >
          </div>
          <div class="emoji-feedback" v-if="rating > 0">
            <span class="emoji">{{ emojiFeedback[rating - 1] }}</span>
            <p class="emoji-text">{{ emojiText[rating - 1] }}</p>
          </div>
          <FormField
            multiline
            placeholder="Ajouter un commentaire (optionnel)"
            v-model="comment"
          />
          <ActionButton
            :label="$t('submitRatingBtnText')"
            @click="submitRating"
          />
          <ActionButton
            buttonType="secondary"
            :label="$t('noThanksBtnText')"
            @click="handleClose"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ActionButton from '@common-components/ActionButton.vue'
  import ThemeYellow from '@app/themes/ThemeYellow.vue'
  import NavigationOverlay from '@app-components/NavigationOverlay.vue'
  import FormField from '@common-components/FormField.vue'
  import { mapActions } from 'vuex'
  import { db } from '@common/firebase' // Import Firestore

  export default {
    name: 'RatingModal',
    components: {
      ActionButton,
      ThemeYellow,
      NavigationOverlay,
      FormField,
    },
    props: {
      show: {
        type: Boolean,
        default: true,
      },
      storyId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        rating: 0,
        comment: '',
        hoverRating: 0,
        emojiFeedback: ['😡', '😞', '😐', '😊', '🤩'],
        emojiText: [
          'Très déçu',
          'Pas terrible',
          'Moyen',
          "J'ai aimé",
          "J'ai adoré !",
        ],
        storyTitle: 'Histoire inconnue', // Valeur par défaut
      }
    },
    computed: {
      navOverlayProps() {
        return {
          hasCloseButton: false,
          hasBackButton: false,
          hasFirstName: false,
          hasPageTitle: true,
          pageTitle: this.$t('rateStoryPageTitle'),
        }
      },

      childFirstName() {
        const profile = this.$store.state.userSession.profile
        return (
          profile.charactersList[profile.lastActiveCharacter]?.char.firstName ||
          'Ton enfant'
        )
      },
    },
    emits: ['closeModal'],
    methods: {
      ...mapActions(['logEvent', 'updateUserProfile']),

      handleClose() {
        this.$emit('closeModal')
      },

      setRating(value) {
        this.rating = value
        this.hoverRating = value
      },

      setHover(value) {
        this.hoverRating = value
      },

      clearHover() {
        this.hoverRating = this.rating
      },

      getStarStyle(star) {
        const active = star <= (this.hoverRating || this.rating)
        return {
          transform: active ? 'scale(1.3)' : 'scale(1)',
          transition: 'transform 0.2s',
          display: 'inline-block',
          filter: active ? 'none' : 'grayscale(100%) opacity(0.5)',
        }
      },

      async fetchStoryTitle() {
        const locale = this.$i18n.locale
        const storyDocId = `${this.storyId}-${locale}`

        try {
          const docRef = db.collection('stories').doc(storyDocId)
          const docSnap = await docRef.get()

          if (docSnap.exists) {
            const meta = JSON.parse(docSnap.data().meta)
            let title = meta.title?.M || 'Histoire inconnue'

            // Remplace {{first_name}} dans le titre
            this.storyTitle = title.replace(
              '{{first_name}}',
              this.childFirstName
            )
          } else {
            console.error(
              `[RatingModal] Document stories/${storyDocId} not found.`
            )
          }
        } catch (error) {
          console.error(`[RatingModal] Error fetching story title:`, error)
        }
      },

      async submitRating() {
        if (this.rating === 0) {
          this.handleClose()
        }

        const userProfile = this.$store.state.userSession.profile

        if (userProfile) {
          let hasRated = userProfile.hasRated || []
          const storyIdStr = this.storyId.toString()

          if (!hasRated.includes(storyIdStr)) {
            hasRated.push(storyIdStr)
            try {
              await this.updateUserProfile({ hasRated })
            } catch (error) {
              console.error('[RatingModal] Failed to update profile:', error)
            }
          }
        }

        this.logEvent({
          eventName: 'story_rating',
          params: {
            storyId: this.storyId,
            rating: this.rating,
            comment: this.comment,
          },
        })

        this.$store.dispatch('triggerToast', {
          type: 'success',
          mainText: this.$t('feedbackSuccessMessage'),
          secondaryText: '',
        })

        this.handleClose()
      },
    },
    async mounted() {
      await this.fetchStoryTitle() // Charge le titre dès que la modale s'affiche
    },
  }
</script>

<style scoped>
  h2 {
    font-weight: 600;
    font-size: min(6.5vw, 30px);
    color: #a880fe;
  }

  .rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .star-rating {
    width: 150px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .star {
    font-size: 2rem;
  }

  .emoji-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .emoji {
    font-size: 2.5rem;
  }

  .emoji-text {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 5px;
    color: #444;
    margin: 3px;
  }

  .explanation-text {
    text-align: center;
    margin: 5px;
  }
</style>
