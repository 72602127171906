<template>
  <div
    :class="[
      'story-container',
      { active: isActive, 'display-only': displayOnly },
    ]"
    @click="handleStoryClick"
  >
    <!-- En mode liste (non preview) : si locked, overlay sur tout le container -->
    <div v-if="locked && !isActive && !displayOnly" class="locked-overlay full">
      <div class="masked-content"></div>
    </div>

    <!-- Conteneur pour l'image -->
    <div class="image-container">
      <img
        class="story-image"
        :src="storyImagePath"
        alt="Story background cover illustration"
      />
      <!-- En mode preview : si locked, overlay sur l'image uniquement -->
      <div
        v-if="locked && isActive && !displayOnly"
        class="locked-overlay image"
      >
        <div class="masked-content"></div>
      </div>
    </div>

    <h2 class="story-title">{{ storyTitle }}</h2>

    <!-- Détails étendus en mode "story-preview" -->
    <div v-if="isActive" class="extended-story-info">
      <div class="fixed-info">
        <div class="story-versions" v-if="storyVersions.length > 1">
          <TextRadioButton
            v-for="version in storyVersions"
            :key="version.name"
            :value="version.name"
            :modelValue="selectedVersion"
            @update:modelValue="selectedVersion = $event"
            class="small"
          >
            {{
              version.name === 'long'
                ? $t('longVersionRadioButtonText')
                : $t('shortVersionRadioButtonText')
            }}
          </TextRadioButton>
        </div>
        <div class="story-info">
          <span class="story-info-item">
            <img
              class="clock"
              src="@app-assets/fa-clock.png"
              alt="Story length"
            />
            <p>{{ storyLength }}</p>
          </span>
          <span class="story-info-item">
            <img class="age" src="@app-assets/fa-age.png" alt="Story age" />
            <p>
              {{ storyMinTargetAge }} {{ $t('fromToTargetAgeLabel') }}
              {{ storyMaxTargetAge }} {{ $t('yearsAgeLabel') }}
            </p>
          </span>
        </div>
      </div>

      <div class="scrollable-content">
        <div class="story-description">
          <h3 v-if="storyDescription">{{ $t('storyDescriptionLabel') }}</h3>
          <p v-if="storyDescription">{{ storyDescriptionWithFirstName }}</p>
          <h3 v-if="nonEmptyStoryObjectives.length > 0">
            {{ $t('storyObjectivesLabel') }}
          </h3>
          <ul v-if="nonEmptyStoryObjectives.length > 0">
            <li
              v-for="(objective, index) in nonEmptyStoryObjectives"
              :key="index"
            >
              {{ objective }}
            </li>
          </ul>
        </div>
      </div>

      <div class="btn-container">
        <ActionButton
          :label="actionButtonLabel"
          class="tilt title-button"
          buttonType="primary"
          @click.stop="launchStory"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import TextRadioButton from '@app-components/TextRadioButton.vue'
  import ActionButton from '@common-components/ActionButton.vue'
  import { mapActions } from 'vuex'

  export default {
    name: 'StoryItem',
    emits: ['changeViewState', 'storyClicked', 'openSettingsModal'],
    components: { TextRadioButton, ActionButton },
    props: {
      firstName: { type: String, default: '' },
      storyId: { type: Number, default: 0 },
      storyTitle: { type: String, default: '' },
      storyDescription: { type: String, default: '' },
      currentViewState: { type: String, default: '' },
      storyVersions: { type: Array, default: () => [] },
      storyObjectives: { type: Object, default: () => ({}) },
      isFree: { type: Boolean, default: false },
      isFreeForAnon: { type: Boolean, default: false },
      displayOnly: { type: Boolean, default: false },
    },
    data() {
      return {
        selectedVersion: 'short',
        showExtendedInfo: false,
        storyDescriptionWithFirstName: '',
      }
    },
    computed: {
      isActive() {
        return (
          this.showExtendedInfo && this.currentViewState === 'story-preview'
        )
      },
      selectedStoryVersion() {
        return (
          this.storyVersions.find((v) => v.name === this.selectedVersion) || {}
        )
      },
      storyLength() {
        return this.selectedStoryVersion.duration || ''
      },
      storyMinTargetAge() {
        return this.selectedStoryVersion.age
          ? this.selectedStoryVersion.age.min
          : ''
      },
      storyMaxTargetAge() {
        return this.selectedStoryVersion.age
          ? this.selectedStoryVersion.age.max
          : ''
      },
      storyImagePath() {
        try {
          return require(
            `@app-assets/stories/${this.storyId}/story_${this.storyId}_thumbnail.png`
          )
        } catch (error) {
          console.error('Failed to load image:', error)
          return ''
        }
      },
      nonEmptyStoryObjectives() {
        return Object.values(this.storyObjectives).filter(
          (obj) => obj.trim() !== ''
        )
      },
      locked() {
        const stripeRole =
          this.$store.state.userSession.profile.stripeRole || ''

        if (stripeRole === 'solo' || stripeRole === 'multi') {
          return false
        }

        if (stripeRole === 'free') {
          return !this.isFree
        }

        return !this.isFreeForAnon
      },
      actionButtonLabel() {
        return this.locked
          ? this.$t('discoverWithStowyPremium')
          : this.$t('readStoryButtonText')
      },
    },
    created() {
      if (this.storyVersions.length === 1) {
        this.selectedVersion = this.storyVersions[0].name
      }
      this.storyDescriptionWithFirstName = this.storyDescription.replace(
        /{{first_name}}/g,
        this.firstName
      )
    },
    methods: {
      ...mapActions(['logEvent']),
      launchStory() {
        if (this.displayOnly) {
          console.log('Launch blocked: displayOnly is true.')
          return
        }

        if (this.locked) {
          console.log(
            'Story is locked. Opening settings modal instead of launching.'
          )
          this.$emit('openSettingsModal')
          return
        }

        console.log(
          'Launching story with ID:',
          this.storyId,
          'and version:',
          this.selectedVersion
        )

        // Retrieve reader data from user session instead of local storage
        let readerData = this.$store.state.userSession.reader
        console.log('Retrieved readerData from user session:', readerData)

        if (!readerData) {
          console.log(
            'readerData not found in user session, checking user profile...'
          )

          // Use the active character from the user profile as a fallback
          const userProfile = this.$store.state.userSession.profile
          console.log('Retrieved user profile:', userProfile)

          if (
            userProfile &&
            userProfile.charactersList &&
            userProfile.charactersList.length > 0
          ) {
            console.log(
              'User has characters, selecting last active character...'
            )
            readerData =
              userProfile.charactersList[userProfile.lastActiveCharacter]
            console.log('Selected active character as readerData:', readerData)
          } else {
            console.log(
              'No user profile or characters found. Initializing empty readerData...'
            )
            readerData = {}
          }
        }

        // Update readerData with the current story position and start timestamp
        console.log('Updating readerData with story position and timestamp...')
        readerData.position = {
          storyId: this.storyId.toString(),
          version: this.selectedVersion,
          pageId: '0',
          textId: '0',
        }
        readerData.startTimestamp = new Date().toISOString()

        console.log('Updated readerData:', readerData)

        // Save the updated readerData in local storage
        console.log('Saving readerData to localStorage...')
        localStorage.setItem('reader', JSON.stringify(readerData))

        // Log event and redirect to story page
        console.log('Logging event: launch_story...')
        this.logEvent({
          eventName: 'launch_story',
          params: { story_id: this.storyId },
        })

        console.log('Redirecting to /story.html...')
        window.location.href = '/story.html'
      },
      handleStoryClick() {
        if (this.displayOnly) return
        // Even if locked, in list mode, activate preview
        this.showExtendedInfo = true
        if (this.currentViewState !== 'story-preview') {
          this.$emit('changeViewState', 'story-preview')
        }
        this.$emit('storyClicked', this.storyId)
      },
      closePreview() {
        this.showExtendedInfo = false
        this.$emit('storyClosed')
      },
    },
  }
</script>

<style scoped>
  .story-container {
    position: relative;
    background-color: white;
    border-radius: min(2.93vw, 15px);
    margin-bottom: min(3.91vw, 20px);
    padding: min(0.98vw, 5px);
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
  }
  .story-container.active {
    position: absolute;
    top: min(19.53vw, 100px);
    left: min(9.77vw, 50px);
    right: min(9.77vw, 50px);
    bottom: min(3.91vw, 20px);
    display: flex;
    flex-direction: column;
    background-color: white;
  }
  .story-container.display-only {
    pointer-events: none;
  }
  .story-image {
    width: 100%;
    border-radius: min(1.95vw, 10px);
    flex-shrink: 0;
  }
  /* Overlay for list mode (non-preview) */
  .locked-overlay.full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* Overlay for preview mode: on the image only */
  .locked-overlay.image {
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
    height: min(35.28vw, 180px);
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: min(1.95vw, 10px);
  }
  .masked-content {
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #ccc, #ffd75d);
    -webkit-mask-image: url('~@app-assets/story-locked.svg');
    mask-image: url('~@app-assets/story-locked.svg');
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;
    border-radius: min(1.95vw, 10px);
  }
  .story-title {
    text-align: center;
    font-weight: 500;
    line-height: min(7.32vw, 37.5px);
    margin: 0;
    padding: min(0.98vw, 5px) min(2.93vw, 15px);
    font-size: min(5.37vw, 27.5px);
    flex-shrink: 0;
  }
  .extended-story-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .fixed-info {
    flex-shrink: 0;
    padding: min(0.98vw, 5px);
  }
  .story-versions {
    display: flex;
    justify-content: center;
    margin-bottom: min(0.98vw, 5px);
  }
  .story-info {
    display: flex;
    justify-content: center;
    margin: min(0.98vw, 5px);
  }
  .story-info-item {
    margin: 0 min(0.98vw, 5px);
    display: flex;
    align-items: center;
  }
  .story-info p {
    margin: 0 min(0.98vw, 5px);
  }
  .clock {
    width: min(4.49vw, 23px);
    height: min(4.39vw, 22.5px);
  }
  .age {
    width: min(2.49vw, 12.75px);
    height: min(4.39vw, 22.5px);
  }
  .scrollable-content {
    flex: 1;
    overflow-y: auto;
    padding: min(0.98vw, 5px);
  }
  .story-description {
    margin: 0 min(1.95vw, 10px);
  }
  .story-description::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }
  .story-description::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 5px;
  }
  .story-description::-webkit-scrollbar-thumb {
    background-color: #a880fe;
    border-radius: 5px;
    border: 2px solid #f9f9f9;
  }
  .story-description h3 {
    margin: min(2.93vw, 15px) 0 0 0;
  }
  .btn-container {
    flex-shrink: 0;
    padding: min(2.93vw, 15px);
    text-align: center;
  }
</style>
