<template>
  <div :class="{ 'animate-spin': isPlayingAnimation }">
    <img
      :src="starryImageSrc"
      alt="Starry"
      class="starry-image"
      v-show="isLaunchVisible && discoverStep < 2"
    />
  </div>
  <img
    src="@app-assets/starry-falling.png"
    class="starry-falling"
    :class="{
      'starry-falling-visible': discoverStep === 2,
      'starry-falling-hidden-top': discoverStep !== 2,
    }"
  />

  <div
    v-if="isLaunchVisible"
    class="welcome-action-zone"
    ref="welcomeActionZone"
  >
    <ActionButton
      :delay="200"
      :label="
        discoverStep === 3
          ? $t('discoverThreeNextButtonText')
          : discoverStep > 0
            ? $t('nextButtonText')
            : $t('discoverText')
      "
      buttonType="primary"
      @click="nextDiscoverStep"
    />
    <ActionButton
      :delay="400"
      :label="$t('veteranText')"
      buttonType="secondary"
      @click="handleVeteranClick"
      v-if="discoverStep === 0 && isAnonymous === true"
    />
    <ActionButton
      :delay="400"
      :label="$t('skipDiscoverButtonText')"
      buttonType="tertiary"
      @click="skipDiscoverClick"
      v-if="discoverStep > 0 && discoverStep < 3"
    />
  </div>
</template>

<script>
  import { useRouter } from 'vue-router'
  import { mapActions } from 'vuex'
  import ActionButton from '@common-components/ActionButton.vue'

  export default {
    name: 'AppHome',
    components: {
      ActionButton,
    },
    emits: ['theme', 'themeMode', 'themeContent', 'logoState'],
    setup() {
      const router = useRouter()
      return { router }
    },
    data() {
      return {
        theme: 'ThemeNature',
        themeMode: 'night',
        themeContent: [['']],
        logoState: 'animated',
        bottomOffset: 0,
        isLaunchVisible: false,
        discoverStep: 0,
        isAnonymous: null, // Initialiser à null pour attendre la mise à jour
        initialText: [
          [
            this.$t('welcomeTitleText1') +
              this.$t('welcomeTitleText1' + Math.floor(Math.random() * 8 + 1)),
            'text-blue',
          ],
          [this.$t('welcomeTitleText2'), 'text-orange'],
          [this.$t('welcomeTitleText3'), 'text-purple'],
        ],
        discoverStepOneText: [
          [this.$t('discoverOneText1')],
          [this.$t('discoverOneText2'), 'text-orange'],
          [this.$t('discoverOneText3')],
        ],
        discoverStepTwoText: [
          [this.$t('discoverTwoText1')],
          [this.$t('discoverTwoText2'), 'text-orange'],
          [this.$t('discoverTwoText3'), 'text-blue'],
        ],
        discoverStepThreeText: [
          [this.$t('discoverThreeText1')],
          [this.$t('discoverThreeText2'), 'text-purple'],
          [this.$t('discoverThreeText3')],
          [this.$t('discoverThreeText4'), 'text-orange'],
        ],
        isPlayingAnimation: false,
      }
    },
    computed: {
      starryImageSrc() {
        switch (this.discoverStep) {
          case 1:
            return require('@app-assets/starry-laughing.png')
          default:
            return require('@app-assets/Starry.png')
        }
      },
    },
    watch: {
      '$store.state.userSession.auth.record': {
        immediate: true,
        handler(newVal) {
          this.isAnonymous = newVal?.isAnonymous || false
        },
      },
    },
    methods: {
      ...mapActions(['logEvent']),

      skipDiscoverClick() {
        this.logEvent({
          eventName: 'skip_discovery',
        })
        this.redirectToApp()
      },

      switchDayNight() {
        this.themeMode = this.themeMode === 'day' ? 'night' : 'day'
        this.$emit('themeMode', this.themeMode)
      },

      setThemeText(arrayText) {
        this.themeContent = arrayText
        this.$emit('themeContent', this.themeContent)
      },

      hideStowyLogo() {
        this.logoState = 'hidden-top'
      },

      launchWelcome() {
        this.switchDayNight()
        this.hideStowyLogo()
        setTimeout(() => {
          this.setThemeText(this.initialText)
          this.isLaunchVisible = true
        }, 2000)
      },

      nextDiscoverStep() {
        if (this.discoverStep === 0) {
          this.logEvent({
            eventName: 'click_discover',
          })
        }

        if (this.discoverStep === 3) {
          this.logEvent({
            eventName: 'discovery_complete',
          })
          this.redirectToApp()
          return
        }
        this.isPlayingAnimation = true

        setTimeout(() => {
          this.discoverStep++
          this.updateBottomOffset()

          switch (this.discoverStep) {
            case 1:
              this.setThemeText(this.discoverStepOneText)
              break
            case 2:
              this.setThemeText(this.discoverStepTwoText)
              this.switchDayNight()
              break
            case 3:
              this.setThemeText(this.discoverStepThreeText)
              break
            default:
              console.debug('¯_(ツ)_/¯ Unknown step')
              break
          }
        }, 500)

        setTimeout(() => {
          this.isPlayingAnimation = false
        }, 1000)
      },

      handleVeteranClick() {
        if (this.isAnonymous) {
          this.redirectToLogin()
        } else {
          this.redirectToApp()
        }
      },

      redirectToLogin() {
        this.logEvent({
          eventName: 'click_used_to',
        })
        this.router.push({
          name: 'AppLogin',
        })
      },

      redirectToApp() {
        localStorage.setItem('hasCompletedOnboarding', 'true')
        this.router.push({
          name: 'CharacterChoice',
        })
      },

      redirectToDiscover() {
        this.router.push({
          name: 'DiscoverStepOne',
        })
      },

      /**
       * The adjustStarryImage() function adjusts the position of 'starry' based on the positions of action buttons and the CloudsDialogBox.
       * It performs the repositioning by manipulating the DOM using querySelector, which is generally not a best practice in Vue.
       * However, due to the significant separation of the two components involved in the calculation (one in the theme and another in a nested component),
       * this approach was taken as a shortcut. Additionally, the positioning is approximated using a linear regression, which may not accurately handle
       * cases with screens having extreme aspect ratios. Also I did something unconventional here, defining screen aspect ratio as H/W instead of W/H.
       * This function should be revisited and refined in the future. It does the job pretty well on most screens though, so I guess it's a ¯\_(ツ)_/¯ for now.
       */

      adjustStarryImage() {
        const aspectRatio = window.innerHeight / window.innerWidth
        if (aspectRatio > 5.3 / 6.5) {
          const a = -0.1,
            b = 0.5333,
            c = 0.125,
            d = 0.0833

          const top = a * aspectRatio + b
          const height = c * aspectRatio + d

          const starryImage = document.querySelector('.starry-image')
          if (starryImage) {
            starryImage.style.top = `${top * 100}vh`
          }
        }
      },

      updateBottomOffset() {
        if (this.discoverStep === 2) {
          this.bottomOffset = 40
        } else if (this.discoverStep === 3) {
          this.bottomOffset = 35
        } else {
          this.bottomOffset = 0
        }
      },
    },

    mounted() {
      this.$store.dispatch('initializeAnalytics')
      this.adjustStarryImage()
      window.addEventListener('resize', this.adjustStarryImage)
      setTimeout(() => {
        this.launchWelcome()
      }, 4500)
    },

    beforeUnmount() {
      window.removeEventListener('resize', this.adjustStarryImage)
    },
  }
</script>

<style scoped>
  .starry-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: min(300px, 60vw);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      opacity: 0;
    }
    100% {
      transform: rotate(2160deg);
    }
  }

  .starry-falling {
    position: absolute;
    left: 10vw;
    width: min(220px, 44vw);
    transition: top 1s ease-in-out;
  }

  .starry-falling-visible {
    top: 0;
  }

  .starry-falling-hidden-top {
    top: -65vh;
  }

  .animate-spin {
    animation: spin 1s ease-in-out;
    height: -webkit-fill-available;
  }

  @media screen and (min-aspect-ratio: 5.3/6.5) {
    .starry-image {
      top: 43vw;
    }
  }

  @media screen and (min-aspect-ratio: 1) {
    .starry-image {
      top: 33vw;
    }
  }

  @media screen and (min-aspect-ratio: 8/6.5) {
    .starry-image {
      top: 23vw;
    }
  }

  @media screen and (min-aspect-ratio: 16/9) {
    .starry-image {
      top: 15vw;
    }
  }
</style>
