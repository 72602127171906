<!-- App.vue -->
<template>
  <div class="app-container">
    <div class="theme-container">
      <component
        :is="themeComponent"
        :themeContent="this.themeContent"
        :themeMode="this.themeMode"
        :logoState="this.logoState"
        :bottomOffset="this.bottomOffset"
      />
    </div>
    <router-view v-slot="{ Component }">
      <div
        :class="[
          'content-container',
          { 'clipped-top-padding': logoState === 'clipped-top' },
        ]"
      >
        <component
          :is="Component"
          @theme="updateTheme"
          @themeMode="updateThemeMode"
          @themeContent="updateThemeContent"
          @logoState="updateLogoState"
          @bottomOffset="updateBottomOffset"
        />
      </div>
    </router-view>
    <HurrayToast />
  </div>
</template>
<script>
  import './styles/colors.css'
  import ThemeYellow from './themes/ThemeYellow.vue'
  import ThemeNature from './themes/ThemeNature.vue'
  import HurrayToast from '@account-components/HurrayToast.vue'

  export default {
    components: {
      ThemeNature,
      ThemeYellow,
      HurrayToast,
    },
    emits: ['closeBtnClicked', 'firstNameBtnClicked', 'backBtnClicked'],
    data() {
      return {
        theme: '',
        themeMode: '',
        themeContent: [['']],
        logoState: 'animated',
        bottomOffset: 0,
        hasCloseButton: false,
        hasBackButton: false,
        pageTitle: 'Page Title',
        firstName: '',
        firstNameVisible: false,
      }
    },
    computed: {
      themeComponent() {
        const themes = {
          ThemeNature: ThemeNature,
          ThemeYellow: ThemeYellow,
        }
        if (themes[this.theme]) {
          return themes[this.theme]
        }

        return ThemeNature
      },
    },
    methods: {
      updateTheme(value) {
        this.theme = value || 'ThemeNature'
      },
      updateThemeMode(value) {
        this.themeMode = value || 'day'
      },
      updateThemeContent(value) {
        this.themeContent = value || []
      },
      updateLogoState(value) {
        this.logoState = value || 'clipped-top'
      },
      updateBottomOffset(value) {
        this.bottomOffset = value || 0
      },
      updateHasCloseButton(value) {
        this.hasCloseButton = value || false
      },
      updateHasBackButton(value) {
        this.hasBackButton = value || false
      },
      updatePageTitle(value) {
        this.pageTitle = value || ''
      },
      updateFirstName(value) {
        this.firstName = value || ''
      },
      updateFirstNameVisible(value) {
        this.firstNameVisible = value || false
      },
    },
  }
</script>

<style src="./styles/colors.css"></style>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;600;700;800&display=swap');

  /* ==== GENERAL STYLES ==== */

  * {
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  body {
    margin: 0;
    padding: 0;
    display: flex;
    font-family: 'Baloo Bhai 2', cursive;
    color: #43474f;
  }

  button {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    background: none;
  }

  /* ==== TYPOGRAPHY ==== */
  p {
    font-size: min(3vw, 15px);
  }

  h1 {
    justify-content: center;
    display: flex;
    font-weight: 600;
    padding: min(1.95vw, 10px) 0 min(1.95vw, 10px) 0;
    margin: min(1.95vw, 10px) 0;
    text-align: center;
    font-size: min(6.5vw, 32.5px);
  }

  h2 {
    font-weight: 500;
    font-size: min(3.9vw, 20px);
  }

  h3 {
    font-size: min(3.9vw, 20px);
    color: #a880fe;
  }

  li {
    font-size: min(3vw, 15px);
    padding-left: min(1.95vw, 10px);
  }

  li::marker {
    color: #a880fe;
    content: '✬';
  }

  ul {
    padding-left: min(3.9vw, 20px);
  }

  /* ==== LAYOUT ==== */
  .app-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .theme-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .content-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 100%;
    display: block;
    padding: 0 min(10vw, 50px);
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 2;
    scrollbar-width: none;
  }

  .content-container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .clipped-top-padding {
    padding-top: 20vh;
  }

  .center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .page-content {
    position: relative;
    top: min(36vw, 180px);
    padding: min(1.95vw, 10px);
    border-radius: min(0.49vw, 2.5px);
    height: 100%;
  }

  .bottom-nav {
    position: absolute;
    bottom: min(43vw, 215px);
    width: 95%;
  }

  .scrollable {
    overflow-y: auto;
    max-height: 100vh;
  }

  .no-scrollable {
    overflow-y: hidden;
  }

  .welcome-action-zone {
    position: relative;
    top: 96vh;
    transform: translateY(-100%);
  }

  /* ==== THEMES ==== */
  .theme {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  .theme-object {
    position: absolute;
  }

  /* ==== HELP SECTION ==== */
  .help-section {
    margin-bottom: min(3.91vw, 20px);
  }

  .help-title {
    display: flex;
    align-items: center;
  }

  .life-ring {
    width: min(2.93vw, 15px);
    height: min(3.02vw, 15.5px);
  }

  .help-title > h2 {
    margin-left: min(1.95vw, 10px);
    margin-top: 0;
    margin-bottom: 0;
  }

  /* ==== MODAL ==== */
  .modal {
    position: fixed;
    z-index: 10002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  /* ==== RADIO BUTTONS ==== */
  .radio-container {
    display: flex;
    justify-content: center;
  }

  /* ==== INTERACTIVE ELEMENTS ==== */
  .surname-btn {
    color: #a880fe;
    text-decoration-line: underline;
    text-decoration-style: dashed;
    padding-left: min(1.46vw, 7.5px);
    cursor: pointer;
    z-index: 100;
    transition: transform 0.3s;
    display: flex;
  }

  .surname-btn:hover {
    transform: scale(1.02);
  }

  .first-name-edit-icon {
    padding-left: min(1.46vw, 7.5px);
    width: min(25px, 5vw);
    height: min(25px, 5vw);
    position: relative;
    top: 25%;
  }

  .letter {
    display: inline-block;
    line-height: 1em;
    transform-origin: 0 0;
  }

  /* === SCROLLBARS ===*/
</style>
