<template>
    <span>{{ this.$t('noConnectivityWarningMessage') }}</span>
    <div class="image-container">
        <img src="@common-assets/404.png" alt="Starry is having some very hard time">
    </div>
    <ActionButton 
        :label="$t('retryButtonMessage')"
        buttonType="primary"
        @click="reloadPage"
    />
</template>

<script>

import { useRouter } from 'vue-router';

import ActionButton from '@common-components/ActionButton.vue'

export default {
    name: 'NoConnectivity',
    components: {
        ActionButton,
    },
    setup() {
        const router = useRouter();
        return { router };
    },
    methods: {
        reloadPage() {
            this.router.push({
                name: "AppHome",
            });
        }
    }
}

</script>

<style scoped>

  .image-container img {
    width: min(300px,60vw); 
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  
</style>